<template>
	<modal class="fs-modal opt-in-modal" name="opt-in-modal" :width="getWidth" :height="getHeight" scrollable="true" :adaptive="true" :clickToClose="false">
		<div class="w-full h-full flex flex-col bg-modal">
			<div class="absolute right-0">
				<!-- <img @click.stop="closeModal" class="cursor-pointer p-4" :src="require('images/landing_page/modals/close.svg')" /> -->
				<button
          title="Close"
          class="focus:outline-none absolute right-0 top-0 mr-4 mt-4 z-50"
      	>
	        <svg
	            @click="closeModal"
	            viewBox="0 0 1024 1024"
	            version="1.1"
	            xmlns="http://www.w3.org/2000/svg"
	            xmlns:xlink="http://www.w3.org/1999/xlink"
	            width="25"
	            height="25"
	            class="icon icon-remove"
	            style="top: -5.5px; right: -15px"
	        >
	          <path
	              d="M511.921231 0C229.179077 0 0 229.257846 0 512 0 794.702769 229.179077 1024 511.921231 1024 794.781538 1024 1024 794.702769 1024 512 1024 229.257846 794.781538 0 511.921231 0ZM732.041846 650.633846 650.515692 732.081231C650.515692 732.081231 521.491692 593.683692 511.881846 593.683692 502.429538 593.683692 373.366154 732.081231 373.366154 732.081231L291.761231 650.633846C291.761231 650.633846 430.316308 523.500308 430.316308 512.196923 430.316308 500.696615 291.761231 373.523692 291.761231 373.523692L373.366154 291.918769C373.366154 291.918769 503.453538 430.395077 511.881846 430.395077 520.349538 430.395077 650.515692 291.918769 650.515692 291.918769L732.041846 373.523692C732.041846 373.523692 593.447385 502.547692 593.447385 512.196923 593.447385 521.412923 732.041846 650.633846 732.041846 650.633846Z"
	              fill="#8c40e2"
	          ></path>
	        </svg>
	      </button>
			</div>
			<div class="bg-mobile-warrior sm:bg-desktop-warrior w-full h-full bg-1 p-8 flex flex-col sm:flex-row">
				<div class="w-full flex sm:hidden ht-200">
					<img :src="require('images/defaults/optin_mobile.png')" alt="optin_mobile">
				</div>
				<div v-if="this.show_form == true" class="w-full sm:w-1/2 flex flex-col justify-center">
					<div class="flex flex-col text-center sm:text-left">
						<h1 class="SofiaPro-Medium text-black text-2xl md:text-3xl">Elevate your leadership.</h1>
						<h1 class="SofiaPro-Medium text-black text-2xl md:text-3xl">Empower your team.</h1>
						<p class="text-base sm:text-lg font-light mt-4 sm:mt-8 pr-0 sm:pr-8">Access our exclusive <span class="font-light" style="white-space: nowrap;">scientifically-developed</span>
								resources for the modern executive to fuel
								your well-being journey and lead with
								confidence.
						</p>
	        		</div>
					<div class="flex flex-col-reverse sm:flex-row flex-wrap mt-6">
						<div class="w-full flex justify-center sm:justify-start">
							<form class="needs-validation" @submit.prevent="handleSubmit">   
								<div class="relative wd-250 md:wd-290">
									<div class="flex absolute inset-y-0 left-0 items-center pl-3 mb-2">
										<svg aria-hidden="true" class="w-6 h-6 text-rspurple" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/></svg>
									</div>
									<input class='fs-input pl-10 border border-rspurple' style="background: transparent;" placeholder="Email" v-model.trim="enteredEmail.val" @keyup="clearValidity('enteredEmail')" autocomplete="off" required  />
									<transition name="val-error">
										<p v-if="!enteredEmail.isValid" class="text-xs text-red">
											Email must not be empty.
										</p>
									</transition>
								</div>
								<div class="relative wd-250 md:wd-290">
									<div class="flex absolute inset-y-0 left-0 items-center pl-3 mb-2">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill h-6 w-6 text-rspurple" viewBox="0 0 16 16">
											<path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
										</svg>
									</div>
									<input class='fs-input pl-10 border border-rspurple' style="background: transparent;" placeholder="Name" v-model.trim="enteredName.val" @keyup="clearValidity('enteredName')" autocomplete="off" required  />
									<transition name="val-error">
										<p v-if="!enteredName.isValid" class="text-xs text-red">
											Name must not be empty.
										</p>
									</transition>
								</div>
								<div class="wd-250 md:wd-290 flex justify-center mt-2">
									<input type="submit" name="commit" value="Subscribe" class="rs-purple-btn w-48 h-12">
								</div>
							</form>
						</div>
					</div>
				</div>
				<div v-else class="w-full sm:w-1/2 flex flex-col justify-center">
					<div class="flex flex-col text-center sm:text-left">
						<h1 class="SofiaPro-Medium text-black font-light text-2xl sm:text-3xl">Thank you.</h1>
						<h1 class="SofiaPro-Medium text-black text-2xl sm:text-3xl">Welcome to Re:Set!</h1>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
export default {
	name: "opt_in",
	data() {
		return {
			enteredName: {
				val: "",
				isValid: true,
			},
			enteredEmail: {
				val: "",
				isValid: true,
				msg: "",
			},
			serverResponse: {
				val: "",
				msg: "",
			},
			formIsValid: true,
			show_form: true,
		};
	},
	computed: {
		getWidth() {
			if (window.innerWidth > 991) {
				return "867";
			} else if (window.innerWidth > 767) {
				return "700";
			} else {
				return "375";
			}
		},
		getHeight() {
			if (window.innerWidth > 767) {
				return "529";
			} else {
				return "auto";
			}
		},
	},
	mounted() {
		this.$modal.show(`opt-in-modal`);
	},
	methods: {
		closeModal() {
			this.setSessionStorageWithExpiry('alreadyShowOptIn', 'alreadyShowOptIn', 3);
			// sessionStorage.setItem('alreadyShowOptIn','alreadyShowOptIn');
			this.clearInputFields();
			this.$modal.hide(`opt-in-modal`);
		},
		clearValidity(input) {
				this[input].isValid = true;
		},
		validateForm() {
			this.formIsValid = true;
			this.serverResponse.val = "";
			if (this.enteredName.val === "") {
					this.enteredName.isValid = false;
					this.formIsValid = false;
			}
			if (this.enteredEmail.val === "") {
					this.enteredEmail.isValid = false;
					this.enteredEmail.msg = "Email must not be empty.";
					this.formIsValid = false;
			} else {
					const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					if (!re.test(this.enteredEmail.val)) {
							this.enteredEmail.isValid = false;
							this.enteredEmail.msg = "You have entered an invalid email address!";
							this.formIsValid = false;
					}
			}
		},
		handleSubmit() {
			this.validateForm();
			if (!this.formIsValid) {
					return;
			}

			const data = {
				name: this.enteredName.val,
				email: this.enteredEmail.val,
			};
			fetch("/opt_in", {
					method: "POST",
					headers: {
					"Content-Type": "application/json",
					},
					body: JSON.stringify(data),
			})
			.then((response) => response.json())
			.then((data) => {
				this.serverResponse.val = data.code;
				this.serverResponse.msg = data.msg;
				if (data.code == "1") {
				  this.show_form = false;
					// this.$modal.hide(`opt-in-modal`);
					this.clearInputFields();
				}else {
					this.serverResponse.val = "0";
				}
			})
			.catch((error) => {
				this.serverResponse.val = "0";
				this.serverResponse.msg = error;
			});
		},
		clearInputFields() {
			this.enteredName.val = "";
			this.enteredEmail.val = "";
		},

		setSessionStorageWithExpiry(key, value, expiryInDays) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + (expiryInDays * 24 * 60 * 60 * 1000) // Calculate the expiration timestamp here we set expiry for 3 days. 
      };
      sessionStorage.setItem(key, JSON.stringify(item));
    }
	},
}
</script>

<style lang="scss" scoped>
	@responsive {
		.bg-desktop-warrior {
			background-image: url('~images/defaults/optin.png');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: bottom right;
		}
		.bg-mobile-warrior {
			background: #e9e1f9;
		}
		.wd-290 {
			width: 290px;
		}
		.wd-250 {
			width: 250px;
		}
		.ht-200 {
			height: 200px;
		}
	}
	.bg-1 {
		background-color: #e6def6;
	}
	.bg-modal {
		background: rgba(0, 0, 0, 0.2);
	}
</style>

<style lang="scss">
	.opt-in-modal {
		.v--modal-box.v--modal {
			border-radius: 0px;
		}
	}
</style>