<template>
  <div id="workplace-solutions" class="flex flex-col pt-16 pb-8 xs:pb-16 px-4">
    <div class="container mx-auto">
      <h1 class="SofiaPro-Medium f-24 xs:f-48 text-center text-color-1 lin-ht-140">How your organization will benefit from an employee <span class="SofiaPro-Medium f-40 xs:f-56 md:f-66 lg:f-80 lin-ht-140 text-color-1" style="white-space: nowrap;">well-being</span> program</h1>
      <div class="flex flex-col items-center justify-center relative">
        <div class="w-full">
          <img class="w-full hidden sm:block" :src="require('images/landing_page/section11/img1.png')" />
          <img class="w-full block sm:hidden" :src="require('images/landing_page/section11/img2.svg')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section11",
  data() {
    return {
    };
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
@responsive {
  .ht-655 {
      height: 655px;
  }

  .lin-ht-140 {
        line-height: 140%;
    }
    .w-320{
        width: 320px;
    }
    .w-230 {
        width: 230px;
    }
    .f-48 {
        font-size: 48px;
    }
    .f-28 {
        font-size: 28px;
    }
    .f-24 {
        font-size: 24px;
    }
    .w-100-per {
        width: 100%;
    }
    .w-310 {
        width: 310px;
    }
    .w-220 {
        width: 220px;
    }
    .w-240 {
        width: 240px;
    }
    .ht-125 {
        height: 125px;
    }
    .ht-115 {
        height: 115px;
    }
    .ht-100 {
        height: 100px;
    }

    .ht-105 {
        height: 105px;
    }
    .ht-95 {
        height: 95px;
    }
    .ht-80 {
        height: 80px;
    }

    .ht-90 {
        height: 90px;
    }
    .ht-75 {
        height: 75px;
    }
}
.text-color-1 {
    color: #09040E;
}
.text-color-2 {
    color: #524F56;
}
.text-color-3 {
    color: #524f56a8;
}
.text-color-4 {
    color: #8B40E2;
}
</style>