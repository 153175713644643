import { render, staticRenderFns } from "./press.vue?vue&type=template&id=deba1304&scoped=true&"
import script from "./press.vue?vue&type=script&lang=js&"
export * from "./press.vue?vue&type=script&lang=js&"
import style0 from "./press.vue?vue&type=style&index=0&id=deba1304&lang=scss&scoped=true&"
import style1 from "./press.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "deba1304",
  null
  
)

export default component.exports