<template>
    <div>
        <modal class="fs-modal static-modal-contact-us" :name="getModalName" :width="getWidth" :height="getHeight" scrollable="true" :adaptive="true" :clickToClose="false">
            <div class="h-full w-full flex flex-col relative">
                <div class="absolute right-0">
                    <img @click.stop="closeModal" class="cursor-pointer p-4" :src="require('images/landing_page/modals/close.svg')" />
                </div>
                <div class="w-full h-full flex flex-col md:flex-row-reverse flex-no-wrap md:flex-wrap">
                    <div class="h-auto md:h-full w-full md:w-1/2 flex flex-col items-end">
                        <img class="w-full md:w-auto object-cover md:object-contain ht-260 md:ht-auto" :src="require('images/landing_page/modals/contact_us/img1.png')" />
                    </div>
                    <div class="h-auto md:h-full w-full md:w-1/2 px-4 xs:px-8 md:px-10 flex-auto overflow-y-hidden">
                        <form class="h-full" @submit.prevent="handleSubmit">
                            <div class="h-full flex flex-col">
                                <div class="flex flex-col mt-4 xs:mt-8 md:mt-16">
                                    <h1 class="SofiaPro-Medium text-black text-xl xs:text-2xl lin-ht-30 xs:lin-ht-36 text-center lg:wd-525">What’s on your mind?</h1>
                                </div>
                                <div class="w-full flex-auto overflow-y-hidden xs:overflow-y-scroll pr-4 mt-4 xs:mt-8 md:mt-12">
                                    <div class="flex flex-col">
                                        <div class="flex flex-row flex-wrap">
                                            <div class="w-full flex justify-start ht-56 rsd-input br-28 items-center">
                                                <div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
                                                    <img :src="require('images/landing_page/modals/img1.svg')">
                                                </div>
                                                <div class="h-full flex-grow flex items-center pr-4">
                                                    <input class="input-btn outline-none text-sm w-full" v-model.trim="enteredFirstName.val" placeholder="First Name" @keyup="clearValidity('enteredFirstName')" autocomplete="off" required />
                                                </div>
                                            </div>
                                            <transition name="val-error">
                                                <p v-if="!enteredFirstName.isValid" class="text-xs text-red">
                                                    First Name must not be empty.
                                                </p>
                                            </transition>
                                            <div class="w-full mt-4 flex justify-start ht-56 rsd-input br-28 items-center">
                                                <div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
                                                    <img :src="require('images/landing_page/modals/img1.svg')">
                                                </div>
                                                <div class="h-full flex-grow flex items-center pr-4">
                                                    <input class="input-btn outline-none text-sm w-full" v-model.trim="enteredLastName.val" placeholder="Last Name" @keyup="clearValidity('enteredLastName')" autocomplete="off" required />
                                                </div>
                                            </div>
                                            <transition name="val-error">
                                                <p v-if="!enteredLastName.isValid" class="text-xs text-red">
                                                    First Name must not be empty.
                                                </p>
                                            </transition>
                                            <div class="w-full mt-4 flex justify-start ht-56 rsd-input br-28 items-center">
                                                <div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
                                                    <img :src="require('images/landing_page/modals/img2.svg')">
                                                </div>
                                                <div class="h-full flex-grow flex items-center pr-4">
                                                    <input class="input-btn outline-none text-sm w-full" v-model.trim="enteredEmail.val" placeholder="Email" @keyup="clearValidity('enteredEmail')" autocomplete="off" required />
                                                </div>
                                            </div>
                                            <transition name="val-error">
                                                <p v-if="!enteredEmail.isValid" class="text-xs text-red">
                                                    {{ enteredEmail.msg }}
                                                </p>
                                            </transition>
                                            <div class="w-full mt-4 flex justify-start ht-56 rsd-input br-28 items-center">
                                                <div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
                                                    <img :src="require('images/landing_page/modals/img3.svg')">
                                                </div>
                                                <div class="h-full flex-grow flex items-center pr-4">
                                                    <input class="input-btn outline-none text-sm w-full" v-model.trim="enteredOrganizationName.val" placeholder="Organization Name" @keyup="clearValidity('enteredOrganizationName')" autocomplete="off" required />
                                                </div>
                                            </div>
                                            <transition name="val-error">
                                                <p v-if="!enteredOrganizationName.isValid" class="text-xs text-red">
                                                    Organization Name must not be empty.
                                                </p>
                                            </transition>
                                        </div>
                                    </div>
                                    <div class="flex flex-col mt-8">
                                        <h1 class="text-color-1 text-xs pl-4">Message*</h1>
                                        <div class="flex flex-row flex-wrap mt-2">
                                            <div class="w-full">
                                                <div class="text-area-div p-4">
                                                    <textarea maxlength="250" class="h-full w-full text-sm outline-none resize-none" placeholder="Type your message here" v-model="enteredMessage.val" @keyup="clearValidity('enteredMessage')"/>
                                                </div>
                                                <transition name="val-error">
                                                    <p v-if="!enteredMessage.isValid" class="text-xs text-red">
                                                        Message must not be empty.
                                                    </p>
                                                </transition>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex flex-col mt-8 mb-4">
                                      <vue-recaptcha ref="recaptcha" :sitekey="recaptchaSiteKey" @verify="onVerify" @expired="onExpired"></vue-recaptcha>
                                    </div>
                                </div>
                                <div class="mt-8 mb-10 flex flex-col items-center">
                                    <div>
                                        <transition name="val-error">
                                            <p class="text-sm text-red mb-2" v-if="serverResponse.val === '0'">{{ serverResponse.msg }}</p>
                                        </transition>
                                    </div>
                                    <button type="submit" name="button" :class="formIsValid == true && verified_user == true ? 'bg-1' : 'bg-darkgrey'" class="ht-56 w-100-per xs:wd-225 md:wd-309 lg:wd-389 br-28 flex justify-center items-center px-8 cursor-pointer">
                                        <div class="flex justify-center SofiaPro-Medium text-white">Submit</div>
                                        <div class="flex-shrink-0 pl-3">
                                            <img :src="require('images/landing_page/modals/arrow.svg')" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
export default {
    name: "contact-us-modal",
    components: {VueRecaptcha},
    props: {
        page: String,
    },
    computed: {
        getModalName() {
            return `${this.page}-contact-us-modal`;
        },
        getWidth() {
            if(window.innerWidth > 1510) {
                return "1360px";
            } else {
                return '95%';
            }
        },
        getHeight() {
            if(window.innerWidth > 479) {
                return "85%";
            } else {
                return 'auto';
            }
        },
    },
    data() {
        return {
            enteredFirstName: {
                val: "",
                isValid: true,
            },
            enteredLastName: {
                val: "",
                isValid: true,
            },
            enteredEmail: {
                val: "",
                isValid: true,
                msg: "",
            },
            enteredOrganizationName: {
                val: "",
                isValid: true,
            },
            enteredMessage: {
                val: "",
                isValid: true,
            },
            serverResponse: {
                val: "",
                msg: "",
            },
            formIsValid: true,
            verified_user: false,
            recaptchaSiteKey:"6Leo2XsoAAAAAAFCEztEMeL2uA5hyj22FLrN-846",
        };
    },
    methods: {
        closeModal() {
            this.clearInputFields();
            this.$modal.hide(`${this.page}-contact-us-modal`);
        },
        clearValidity(input) {
            this[input].isValid = true;
        },
        validateForm() {
            this.formIsValid = true;
            this.serverResponse.val = "";
            if (this.enteredFirstName.val === "") {
                this.enteredFirstName.isValid = false;
                this.formIsValid = false;
            }
            if (this.enteredLastName.val === "") {
                this.enteredLastName.isValid = false;
                this.formIsValid = false;
            }
            if (this.enteredMessage.val === "") {
                this.enteredMessage.isValid = false;
                this.formIsValid = false;
            }
            if (this.enteredOrganizationName.val === "") {
                this.enteredOrganizationName.isValid = false;
                this.formIsValid = false;
            }
            if (this.enteredEmail.val === "") {
                this.enteredEmail.isValid = false;
                this.enteredEmail.msg = "Email must not be empty.";
                this.formIsValid = false;
            } else {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!re.test(this.enteredEmail.val)) {
                    this.enteredEmail.isValid = false;
                    this.enteredEmail.msg = "You have entered an invalid email address!";
                    this.formIsValid = false;
                }
            }
        },
        handleSubmit() {
            this.validateForm();
            if (!this.formIsValid) {
                return;
            }

            const data = {
                first_name: this.enteredFirstName.val,
                last_name: this.enteredLastName.val,
                email: this.enteredEmail.val,
                organization_name: this.enteredOrganizationName.val,
                message: this.enteredMessage.val,
            };
            fetch("/contact_us", {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
            .then((response) => response.json())
            .then((data) => {
                this.serverResponse.val = data.code;
                this.serverResponse.msg = data.msg;
                if (data.code == "1") {
                    this.$modal.hide(`${this.page}-contact-us-modal`);
                    this.clearInputFields();
                } else {
                    this.serverResponse.val = "0";
                }
            })
            .catch((error) => {
                this.serverResponse.val = "0";
                this.serverResponse.msg = error;
            });
        },
        clearInputFields() {
            this.enteredFirstName.val = "";
            this.enteredLastName.val = "";
            this.enteredOrganizationName.val = "";
            this.enteredEmail.val = "";
            this.enteredMessage.val = "";
            this.serverResponse.val = "";
            this.serverResponse.msg = "";
        },

         onVerify(response) {
          // console.log('Verify: ' + response)
          if(response){
            this.verified_user = true
          }
        },

        onExpired(){
          this.verified_user = false
        }
    }
}
</script>

<style lang="scss" scoped>
    .fs-modal {
        background-color: #000000d6;
    }
    .rsd-input {
        border: 1px solid #ECECEC;
        background-color: white;
    }
    .input-btn {
        outline: 0;
    }
    .br-28 {
        border-radius: 28px;
    }
    .bg-1 {
        background: #8B40E2;
    }
    .text-area-div {
        height: 180px;
        background: #FFFFFF;
        border: 1px solid #ECECEC;
        border-radius: 28px;
    }
    .val-error-enter-active,
    .val-error-leave-active {
        transition: opacity 1s ease;
    }
    .val-error-enter, .val-error-leave-to {
        opacity: 0;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .text-color-1 {
        color: #848186;
    }
    .text-color-2 {
        color: #524F56;
    }
    @responsive {
        .wd-525 {
            width: 525px;
        }
        .ht-56 {
            height: 56px;
        }
        .wd-389 {
            width: 389px;
        }
        .wd-309 {
            width: 309px;
        }
        .wd-225 {
            width: 225px;
        }
        .ht-260 {
            height: 260px;
        }
        .ht-auto {
            height: auto;
        }
        .lin-ht-36 {
            line-height: 36px;
        }
        .lin-ht-30 {
            line-height: 30px;
        }
        .w-100-per {
            width: 100%;
        }
        .lin-ht-24 {
            line-height: 24px;
        }
    }
</style>

<style lang="scss">
    .v--modal-box.v--modal {
        border-radius: 12px;
    }
    @media only screen and (max-width: 480px) {
      .static-modal-contact-us {
        .v--modal-background-click {
          .v--modal-box.v--modal {
              margin-top: 40px !important;
              margin-bottom: 40px !important;
          }
        }
      }
    }
</style>