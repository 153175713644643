<template>
  <div class="h-full flex flex-col p-4">
    <p class="px-4 text-base xs:text-lg mt-0 xs:mt-4">Join leading organizations who use Re:Set to help their employees cultivate positive habits and thrive in their personal and professional lives.</p>
    <div class="flex justify-center mt-8">
      <button @click="nextTab" type="button" class="bg-1 text-white py-1 px-4 xs:px-6 rounded-full cursor-pointer"><span class="text-xxs xs:text-xs">Access Re:Set Premium Resources for Free</span></button>
    </div>
    <div class="flex-auto flex flex-col justify-end items-end cursor-pointer mt-4" @click.prevent="openContactPopup()">
      <div class="text-sm text-color-1 leading-none">Contact Us</div>
    </div>
  </div>
  
</template>

<script>
export default {
  name: "one",
  emits: ["next-tab"],
  props: {
    selectedTab: String,
  },
  data() {
    return {  
    };
  },
  methods: {
    nextTab() {
      this.$emit('next-tab', "two");
    },

    openContactPopup(){
      this.$parent.show_contact_us = true;
    }
  },
}
</script>

<style lang="scss" scoped>
.bg-1 {
    background-color: #8B40E2;
}
.text-color-1 {
    color: #8B40E2;
}
@responsive {
  .text-xxs {
    font-size: 11px;
  }
  .text-xs {
    font-size: 0.75rem;
  }
}
</style>