<template>
    <div id="our-offerings" class="flex flex-col py-16 px-4">
        <div class="container mx-auto">
            <div class="flex flex-row flex-wrap">
                <div class="w-full sm:w-1/2">
                    <img :src="require('images/landing_page/section2/img1.png')" class="hidden xs:flex">
                    <img :src="require('images/landing_page/section2/img1-xs.png')" class="block xs:hidden w-full">
                </div>
                <div class="w-full sm:w-1/2 flex flex-col justify-center pl-0 sm:pl-8 mt-8 sm:mt-0">
                    <h1 class="SofiaPro-Medium f-24 xs:f-36 md:f-40 lin-ht-140 text-color-1 lg:w-500"><span class="SofiaPro-Medium f-24 xs:f-36 md:f-40 lin-ht-140 text-color-1 lg:w-500" style="white-space: nowrap;">Scientifically-backed,</span> <span class="SofiaPro-Medium f-24 xs:f-36 md:f-40 lin-ht-140 text-color-1 lg:w-500" style="white-space: nowrap;">expert-designed,</span> and personalized workplace <span class="SofiaPro-Medium f-24 xs:f-36 md:f-40 lin-ht-140 text-color-1 lg:w-500" style="white-space: nowrap;">well-being</span> programs for your most invaluable asset: your people.</h1>
                    <p class="text-color-2 text-base xs:text-lg lin-ht-160 mt-4 lg:w-450">Increase your bottom line by boosting employee productivity and safeguarding your human capital with Re:Set’s workplace solutions.</p>
                    <div class="flex justify-start items-center mt-12">
                        <div @click.stop="scrollToElement('#how-we-work', -88)" class="flex items-center justify-center ht-56 w-100-per xs:wd-240 bg-1 br-28 cursor-pointer px-8">
                            <div class="text-white flex-grow sm:flex-shrink-0 lg:flex-grow flex justify-center SofiaPro-Medium">Learn how</div>
                            <div class="flex-shrink-0 ml-0 sm:ml-2 lg:ml-0">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "section2",
    methods: {
        scrollToElement(selector, yOffset = 0) {
            const el = document.querySelector(selector);
            if (el) {
                const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }       
        },
    },
}
</script>

<style lang="scss" scoped>
@responsive {
    .f-40 {
        font-size: 40px;
    }
    .f-36 {
        font-size: 36px;
    }
    .f-24 {
        font-size: 24px;
    }
    .lin-ht-140 {
        line-height: 140%;
    }
    .lin-ht-160 {
        line-height: 160%;
    }
    .ht-56 {
        height: 56px;
    }
    .wd-240 {
        width: 240px;
    }
    .w-100-per {
        width: 100%;
    }
    .w-500 {
        width: 500px;
    }
    .w-450 {
        width: 450px;
    }
}
.text-color-1 {
    color: #09040E;
}
.text-color-2 {
    color: #524F56;
}
.text-color-3 {
    color: #8B40E2;
}
.bg-1 {
    background: #8B40E2;
}
.br-28 {
    border-radius: 28px;
}
.bor-line {
    border: 1px solid #DCC6F6;
}
</style>