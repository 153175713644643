<template>
  <div class="w-full bg-white">
    <div v-if="hide_navbar === 'show'" :class="isShowBgWhite ? 'bg-white-dessktop' : 'bg-lightpurple-mobile sm:bg-white-dessktop'" class="w-full ht-88 flex items-center z-50 fixed border-b border-lightgrey px-4">
      <div class="container mx-auto"> 
          <div class="flex justify-between items-center">
              <div class="flex-shrink-0">
                  <a href="/">
                      <img class="ht-36" :src="require('images/reset_youreveryday_logo.png')">
                  </a>
              </div>
              <div class="hidden flex-grow sm:flex justify-end items-center text-xs md:text-base">
                <a href="/about" class="cursor-pointer text-color-1">About</a>
                <div @click.stop="scrollToElement('#how-we-work', -88)" class="ml-1 xs:ml-2 sm:ml-4 md:ml-6 lg:ml-12 text-color-1 cursor-pointer flex-shrink-0">How We Work</div>
                <div @click.stop="scrollToElement('#lets-go', -88)" class="ml-1 xs:ml-2 sm:ml-4 md:ml-6 lg:ml-12 text-color-1 cursor-pointer flex-shrink-0">Workplace Solutions</div>
                <!-- <a href="/resources" class="ml-1 xs:ml-2 sm:ml-4 md:ml-6 lg:ml-12 cursor-pointer text-color-1">Resources</a> -->
                <div class="relative ml-1 xs:ml-0 sm:ml-4 md:ml-6 lg:ml-12 text-color-1 cursor-pointer flex-shrink-0 group" x-data="{ open_dropdown: false }">
                  <span @mouseover="open_dropdown = true">
                    Resources
                    <svg class="ml-1 h-4 w-4 inline-block fill-current text-color-1 transform group-hover:rotate-180 transition-transform duration-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 12l-7-7 1.5-1.5L10 9l5.5-5.5L17 5z"/></svg>
                  </span>
                  <div v-show="open_dropdown === true" @mouseover="open_dropdown = true" @mouseout="open_dropdown = false" class="absolute mt-2 bg-white border border-lightgrey rounded-md shadow-lg">
                    <a href="/resources" class="block px-4 py-2 cursor-pointer text-color-1" tabindex="-1" role="menuitem">Blogs</a>
                    <a href="/newsletter" class="block px-4 py-2 cursor-pointer text-color-1" tabindex="-1" role="menuitem">Newsletter</a>
                  </div>
                </div>
                <a href="/contact" class="ml-1 xs:ml-2 sm:ml-4 md:ml-6 lg:ml-12 cursor-pointer text-color-1">Contact</a>
                <div class="flex ml-1 xs:ml-2 sm:ml-4 md:ml-6 lg:ml-12">
                    <a :href="loginLink" class="cursor-pointer text-color-1">Log In</a>
                    <!-- <div class="mx-1 text-color-1">/</div>
                    <a :href="registerLink" class="cursor-pointer text-color-1">Register</a> -->
                </div>
                <div @click="openRequestADemoPopUp" class="f-11 md:f-16 ml-1 xs:ml-2 sm:ml-4 md:ml-6 lg:ml-12 cursor-pointer text-white wd-100 md:wd-150 lg:wd-200 ht-35 md:ht-40 lg:ht-56 flex justify-center items-center bg-1 br-28">Request a Demo</div>
              </div>
              <div class="flex flex-grow sm:hidden justify-end items-center">
                <mobile-menu></mobile-menu>
              </div>
          </div>
      </div>
    </div>
    <div v-if="hide_navbar === 'show'" class="ht-88"></div>
    <!-- request a demo modal start -->
    <request-a-demo :page="modalPageName"></request-a-demo>
    <!-- request a demo modal end -->
  </div>
</template>

<script>
import MobileMenu from "./mobile_menu";
import RequestADemo from "../modals/request_a_demo.vue";

export default {
    name: "header",
    components: { MobileMenu, RequestADemo, },
    props: {
        hide_navbar: String,
        showBgWhite: String,
    },
    data() {
        return {
            loginLink: "/users/login",
            registerLink: "/users/register",
            modalPageName: "header",
            open_dropdown: false,
        };
    },
    computed: {
      isShowBgWhite() {
        if (this.showBgWhite === "no") {
          return false;
        } else {
          return true;
        }
      },
    },
    methods: {
      openRequestADemoPopUp() {
        this.$modal.show(`${this.modalPageName}-request-a-demo-modal`);
      },
      scrollToElement(selector, yOffset = 0) {
        const el = document.querySelector(selector);
        if (el) {
            const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        } else {
            if (selector == '#how-we-work')
              window.location = '/#how-we-works';
            else{
              window.location = '/#lets-go';
            }
        }            
      },
    },
}
</script>

<style lang="scss" scoped>
.text-color-1 {
  color: #524F56;
}
.bg-1 {
  background: #8B40E2;
}
.br-28 {
  border-radius: 28px;
}
@responsive {
  .ht-88 {
      height: 88px;
  }
  .ht-56 {
      height: 56px;
  }
  .ht-40 {
      height: 40px;
  }
  .ht-36 {
    height: 36px;
  }
  .wd-150 {
      width: 150px;
  }
  .wd-100 {
      width: 100px;
  }
  .wd-200 {
      width: 200px;
  }
  .ht-35 {
    height: 35px;
  }
  .bg-white-dessktop {
    background: #ffffff;
  }
  .bg-lightpurple-mobile {
    background: #f4f3fa;
  }
  .f-11 {
    font-size: 11px;
  }
  .f-16 {
    font-size: 16px;
  }
}
</style>