<template>
    <div class="flex flex-col py-16 px-4">
        <div class="container mx-auto">
            <h1 class="SofiaPro-Medium f-24 xs:f-48 text-center text-color-1 lin-ht-140">Build resilient and <span style="white-space: nowrap;">high-performing</span> teams</h1>
            <div class="w-full flex justify-center">
                <p class="text-xs xs:text-xl text-center lin-ht-140 text-color-2 sm:w-700">Double employee happiness and reduce burnout by 90% with <span style="white-space: nowrap;">end-to-end</span> mental health care through our five <span style="white-space: nowrap;">psychologically-backed</span> pillars.</p>
            </div>
            <div class="hidden sm:flex justify-around mt-16 flex-wrap sm:flex-no-wrap">
                <div class="w-100-per xs:w-230 md:w-320 flex flex-col items-center">
                    <div class="">
                        <img class="ht-100 md:ht-115 lg:ht-125 w-full" :src="require('images/landing_page/section10/img1.svg')" />
                    </div>
                    <h1 class="mt-8 SofiaPro-Medium text-center text-color-4 uppercase lin-ht-140 text-lg xs:text-xl">Mental Fortitude</h1>
                    <p class="text-center text-color-2 lin-ht-140">Cultivate happiness and inner strength to tackle the challenges that come your way.</p>
                </div>
                <div class="w-100-per xs:w-230 md:w-320 flex flex-col items-center mt-16 xs:mt-0">
                    <div class="">
                        <img class="ht-100 md:ht-115 lg:ht-125 w-full" :src="require('images/landing_page/section10/img21.svg')" />
                    </div>
                    <h1 class="mt-8 SofiaPro-Medium text-center text-color-4 uppercase lin-ht-140 text-lg xs:text-xl">Social Agility</h1>
                    <p class="text-center text-color-2 lin-ht-140 md:w-310">Foster <span style="white-space: nowrap;">self-love</span> and learn practical ways to forge strong bonds, and build empathy.</p>
                </div>
                <div class="w-100-per xs:w-230 md:w-320 flex flex-col items-center mt-16 xs:mt-0">
                    <div class="">
                        <img class="ht-100 md:ht-115 lg:ht-125 w-full" :src="require('images/landing_page/section10/img3.svg')" />
                    </div>
                    <h1 class="mt-8 SofiaPro-Medium text-center text-color-4 uppercase lin-ht-140 text-lg xs:text-xl">Mindfulness</h1>
                    <p class="text-center text-color-2 lin-ht-140 md:w-220">Master the skill of acceptance and letting go.</p>
                </div>
            </div>
            <div class="hidden sm:flex justify-around flex-wrap sm:flex-no-wrap mt-8 md:mt-12 lg:mt-16">
                <div class="w-100-per xs:w-230 md:w-320 flex flex-col items-center">
                    <div class="ht-100 md:ht-115 lg:ht-125">
                        <img class="ht-80 md:ht-95 lg:ht-105 w-full mt-5" :src="require('images/landing_page/section10/img4.svg')" />
                    </div>
                    <h1 class="mt-8 SofiaPro-Medium text-center text-color-4 uppercase lin-ht-140 text-lg xs:text-xl">Constructive <span class="normal-case">Re:Set</span></h1>
                    <p class="text-center text-color-2 lin-ht-140 md:w-220">Build and develop micro habits for lasting change.</p>
                </div>
                <div class="w-100-per xs:w-230 md:w-320 flex flex-col items-center mt-16 xs:mt-0">
                    <div class="">
                        <img class="ht-100 md:ht-115 lg:ht-125 w-full" :src="require('images/landing_page/section10/img5.svg')" />
                    </div>
                    <h1 class="mt-8 SofiaPro-Medium text-center text-color-4 uppercase lin-ht-140 text-lg xs:text-xl">Cognitive Acuity</h1>
                    <p class="text-center text-color-2 lin-ht-140 md:w-240">Develop a growth mindset and flourish in your professional life.</p>
                </div>
            </div>

            <div class="flex sm:hidden flex-row flex-wrap mt-4 xs:mt-8">
                <div class="w-1/2 flex flex-col items-center">
                    <div class="">
                        <img class="ht-75 xs:ht-90 w-full" :src="require('images/landing_page/section10/img1.svg')" />
                    </div>
                    <h1 class="mt-4 xs:mt-8 SofiaPro-Medium text-center text-color-4 uppercase lin-ht-140 text-base xs:text-lg">Mental Fortitude</h1>
                    <p class="text-center text-color-2 lin-ht-140 text-xs xs:text-sm">Cultivate happiness and inner strength to tackle the challenges that come your way.</p>
                </div>
                <div class="w-1/2 flex flex-col items-center">
                    <div class="">
                        <img class="ht-75 xs:ht-90 w-full" :src="require('images/landing_page/section10/img21.svg')" />
                    </div>
                    <h1 class="mt-4 xs:mt-8 SofiaPro-Medium text-center text-color-4 uppercase lin-ht-140 text-base xs:text-lg">Social Agility</h1>
                    <p class="text-center text-color-2 lin-ht-140 text-xs xs:text-sm">Foster <span style="white-space: nowrap;">self-love</span> and learn practical ways to forge strong bonds, and build empathy.</p>
                </div>
                <div class="w-full flex justify-center mt-4 xs:mt-8">
                    <div class="w-1/2 flex flex-col items-center">
                        <div class="">
                            <img class="ht-75 xs:ht-90 w-full" :src="require('images/landing_page/section10/img4.svg')" />
                        </div>
                        <h1 class="mt-4 xs:mt-8 SofiaPro-Medium text-center text-color-4 uppercase lin-ht-140 text-base xs:text-lg">Constructive <span class="normal-case">Re:Set</span></h1>
                        <p class="text-center text-color-2 lin-ht-140 text-xs xs:text-sm">Build and develop micro habits for lasting change.</p>
                    </div>
                </div>
                <div class="w-1/2 flex flex-col items-center mt-4 xs:mt-8">
                    <div class="">
                        <img class="ht-75 xs:ht-90 w-full" :src="require('images/landing_page/section10/img3.svg')" />
                    </div>
                    <h1 class="mt-4 xs:mt-8 SofiaPro-Medium text-center text-color-4 uppercase lin-ht-140 text-base xs:text-lg">Mindfulness</h1>
                    <p class="text-center text-color-2 lin-ht-140 text-xs xs:text-sm xs:w-210">Master the skill of acceptance and letting go.</p>
                </div>
                <div class="w-1/2 flex flex-col items-center mt-4 xs:mt-8">
                    <div class="">
                        <img class="ht-75 xs:ht-90 w-full" :src="require('images/landing_page/section10/img5.svg')" />
                    </div>
                    <h1 class="mt-4 xs:mt-8 SofiaPro-Medium text-center text-color-4 uppercase lin-ht-140 text-base xs:text-lg">Cognitive Acuity</h1>
                    <p class="text-center text-color-2 lin-ht-140 text-xs xs:text-sm">Develop a growth mindset and flourish in your professional life.</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "section10",
}
</script>

<style lang="scss" scoped>
@responsive {
    .w-700 {
        width: 700px;
    }
    .lin-ht-140 {
        line-height: 140%;
    }
    .w-320{
        width: 320px;
    }
    .w-230 {
        width: 230px;
    }
    .f-48 {
        font-size: 48px;
    }
    .f-28 {
        font-size: 28px;
    }
    .f-24 {
        font-size: 24px;
    }
    .w-100-per {
        width: 100%;
    }
    .w-310 {
        width: 310px;
    }
    .w-220 {
        width: 220px;
    }
    .w-240 {
        width: 240px;
    }
    .ht-125 {
        height: 125px;
    }
    .ht-115 {
        height: 115px;
    }
    .ht-100 {
        height: 100px;
    }

    .ht-105 {
        height: 105px;
    }
    .ht-95 {
        height: 95px;
    }
    .ht-80 {
        height: 80px;
    }

    .ht-90 {
        height: 90px;
    }
    .ht-75 {
        height: 75px;
    }
}
.text-color-1 {
    color: #09040E;
}
.text-color-2 {
    color: #524F56;
}
.text-color-3 {
    color: #524f56a8;
}
.text-color-4 {
    color: #8B40E2;
}
</style>