<template>
    <div>
        <!-- Buttons -->
        <label for="slide-menu-right" class="menu-button"><i @click="openMenu" class="fa fa-bars text-color text-3xl cursor-pointer"></i></label>

        <!-- Menu 1 -->
        <div class="slide-menu">
            <input id="slide-menu-right" class="menu-toggle" type="checkbox" />
            <div class="menu-wrap">
                <div class="pt-8 pb-4 flex items-center justify-between px-10 b-b">
                    <div>
                        <a href="/">
                            <img class="ht-36" :src="require('images/reset_youreveryday_logo.png')">
                        </a>
                    </div>
                    <label ref="closeBtn" for="slide-menu-right" class="menu-close">
                        <svg @click="closeMenu" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-color cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </label>
                </div>
                <div class="menu-list flex flex-col">
                    <ul>
                        <li class="mt-12">
                            <a @click.prevent="menuClicked('/')" href="/" class="text-color">Home</a>
                        </li>
                        <li>
                            <a @click.prevent="menuClicked('/about')" href="/about" class="text-color">About</a>
                        </li>
                        <li>
                            <div @click.stop="scrollToElement('#how-we-work', -88)" class="text-color cursor-pointer">How We Work</div>
                        </li>
                        <li>
                            <div @click.stop="scrollToElement('#lets-go', -88)" class="text-color cursor-pointer">Workplace Solutions</div>
                        </li>
                        <!-- <li>
                          <a href="/resources" class="text-color">Resources</a>
                        </li> -->
                        <li>
                          <div class="relative ml-0 xs:ml-0 sm:ml-4 md:ml-6 lg:ml-12 text-color cursor-pointer flex-shrink-0 group" x-data="{ open_dropdown: false }">
                            <span @click="open_dropdown = !open_dropdown">
                              Resources
                              <svg class="ml-1 h-4 w-4 inline-block fill-current text-color transform group-hover:rotate-180 transition-transform duration-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 12l-7-7 1.5-1.5L10 9l5.5-5.5L17 5z"/></svg>
                            </span>
                            <!-- <div v-show="open_dropdown === true" class="absolute mt-2 bg-white border border-lightgrey rounded-md shadow-lg">
                              <a href="/resources" class="block px-4 py-2 text-sm text-color-1" tabindex="-1" role="menuitem">Blogs</a>
                              <a href="/contact" class="block px-4 py-2 text-sm text-color-1" tabindex="-1" role="menuitem">Newsletter</a>
                            </div> -->
                            <div v-show="open_dropdown === true" class="mt-2 bg-white border border-lightgrey rounded-md shadow-sm">
                               <a href="/resources" class="block px-2 py-2 text-color hover:text-rspurple" tabindex="-1" role="menuitem">Blogs</a>
                              <a href="/newsletter" class="block px-2 py-2 text-color" tabindex="-1" role="menuitem">Newsletter</a>
                              <!-- Add more tabs as needed -->
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="/contact" class="text-color">Contact</a>
                        </li>
                        <li>
                            <div @click="openRequestADemoPopUp" class="text-color">Request a Demo</div>
                        </li>
                        <li class="mt-24">
                            <a @click.prevent="menuClicked(loginLink)" v-bind:href="loginLink">
                                <button class="login-btn-sticky text-center w-24 h-31 rounded-full cursor-pointer outline-none font-normal border no-select">
                                Log In
                                </button>
                            </a>
                        </li>
                        <!-- <li>
                            <a @click.prevent="menuClicked(registerLink)" v-bind:href="registerLink">
                                <button class="sign-btn-sticky text-center text-white w-24 h-31 rounded-full cursor-pointer outline-none font-normal no-select">
                                SIGN UP
                                </button>
                            </a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <label class="menu-overlay"></label>
        </div>
    </div>
</template>
<script>
export default {
    name: "mobile-menu",
    data() {
        return {
            loginLink: "/users/login",
            registerLink: "/users/register",
            modalPageName: "header",
            open_dropdown: false,
        }
    },
    methods: {
        openMenu() {
            document.body.style.overflow = "hidden";
        },
        closeMenu() {
            document.body.style.overflow = "auto";
        },
        menuClicked(link) {
            this.$refs.closeBtn.click();
            setTimeout(function(){ window.location = link; },300);
        },
        openRequestADemoPopUp() {
            this.$refs.closeBtn.click();
            let $this = this;
            setTimeout(function(){ 
                $this.$modal.show(`${$this.modalPageName}-request-a-demo-modal`);
            },300);
        },
        scrollToElement(selector, yOffset = 0) {
          this.$refs.closeBtn.click();
          document.body.style.overflow = "auto";
          setTimeout(function(){ 
              const el = document.querySelector(selector);
              if (el) {
                const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
              }else {
                if (selector == '#how-we-work')
                  window.location = '/#how-we-works';
                else{
                  window.location = '/#lets-go';
                }
              }     
           },300);
        },
    },
}
</script>
<style lang="scss" scoped>
.no-scroll {
    margin: 0;
    height: 100vh;
    overflow: hidden;
}
.no-select {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  box-shadow: none;
}
.h-31 {
    height: 31px;
}
.sign-btn-sticky {
    background-color: #10083c;
    border-color: #10083c;
}
.login-btn-sticky {
    border-color: #10083c;
    color: #10083c;
}
.pb-100 {
    padding-bottom: 100px;
}
.b-b {
    border-bottom: 1px solid #dcdcdc;
}
.text-color {
    color: #10083c;
}
.h-27 {
    height: 27px;
}
.text-color-blue {
    color: #404A68;
}
.menu-wrap {
    background-color: white;
    overflow: hidden;
    transition: all 0.3s;
    //transition: all 900ms cubic-bezier(.9, 0, .33, 1);
    position: fixed;
    width: 300px;
    top: 0;
    right: -300px;
    bottom: 0;
    z-index: 999;
}
.menu-overlay {
    background: rgba(0, 0, 0, 0.4);
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 900;
}
.menu-toggle {
    display: none;
}
.menu-toggle:checked + .menu-wrap {
    right: 0;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}
.menu-toggle:checked ~ .menu-overlay {
    display: block;
}
.menu-list {
    position: absolute;
    top: 64px;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: inherit;
    display: block;
    overflow: hidden;
}
.menu-wrap:hover .menu-list {
    overflow-y: auto;
}
.menu-list ul {
    list-style-type: none;
}
.menu-list li {
    display: block;
}
.menu-list li:first-child {
    // padding-top: 10px;
}
.menu-list li a {
    display: block;
    padding: 15px 40px;
    font-size: 18px;
    text-decoration: none;
}
.menu-list li div {
    display: block;
    padding: 15px 40px;
    font-size: 18px;
    text-decoration: none;
}
// .menu-list li a:hover {
//     background: #359BED;
// }
.menu-list li a:active {
    background: #666;
}
@responsive {
  .ht-36 {
    height: 36px;
  }
}
</style>