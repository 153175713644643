<template>
    <div>
      <div class="fixed right-0 bottom-0 z-51">
        <div class="flex flex-col items-end">
            <transition name="fade" mode="out-in">
              <div v-if="isBtnClicked" id="pop-up-div" class="pop-up-div xs:pop-up-div-lg mr-2 ml-2 xs:ml-0 mb-2 bg-white flex flex-col" :style="getThirdComponentMobileHeight()">
                  <div class="relative">
                      <img :src="require('images/fixed_contact_us_pop_up/contact_us.png')" class="btr-20 xs:btr-20-lg ht-200 xs:ht-auto w-full object-contain bg-2">
                      <div class="absolute top-0 right-0 mr-2 mt-2" @click.stop="closePopup()">
                          <div class="pop-up-close-btn p-1 cursor-pointer">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 xs:w-6 h-4 xs:h-6 text-white">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                              </svg>
                          </div>
                      </div>
                  </div>
                  <div class="flex-auto overflow-y-auto flex flex-col">
                      <transition name="fade" mode="out-in">
                          <KeepAlive>
                              <component :is="selectedTab" :selected-tab="selectedTab" @next-tab="nextTab"></component>
                          </KeepAlive>
                      </transition>
                  </div>
              </div>
            </transition>
            <div class="bg-1 p-2 xs:p-3 rounded-full cursor-pointer mr-2 xs:mr-4 mb-2 xs:mb-4 relative border-2" @click.stop="isBtnClicked = !isBtnClicked">
                <transition name="fade-scale" mode="out-in">
                    <svg key=1 v-if="isBtnClicked" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 xs:w-8 sm:w-10 h-5 xs:h-8 sm:h-10 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    <svg key=2 v-else xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 xs:w-8 sm:w-10 h-5 xs:h-8 sm:h-10">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z" />
                    </svg>
                </transition>
                <transition name="fade-scale" mode="out-in">
                  <div v-if="!isBtnClicked" class="absolute top-0 left-0 w-3 xs:w-5 h-3 xs:h-5 bg-red rounded-md flex justify-center items-center">
                    <span class="f-8 xs:f-12 text-white">1</span>
                  </div>
                </transition>
            </div>
        </div>
      </div>
      <contact-us v-if="show_contact_us" landing_page="true"></contact-us>
    </div>
    
</template>

<script>
import ContactUs from './../../../components/contact_us.vue'
import One from "./fixed_contact_us_pop_up_components/one.vue";
import Two from "./fixed_contact_us_pop_up_components/two.vue";
import Three from "./fixed_contact_us_pop_up_components/three.vue";

export default {
    name: "fixed-contact-us-pop-up",
    components: { One, Two, Three, ContactUs},
    data() {
      return {
        isBtnClicked: false,
        selectedTab: "one",
        show_contact_us: false,
        thirdComponentMobileHeight: 0,
      };
    },
    watch: {
      selectedTab(newVal, _) {
        if(newVal === 'three') {
          const height = document.getElementById('pop-up-div').offsetHeight;
          this.thirdComponentMobileHeight = height;
        }
      },
    },
    methods: {
      nextTab(tab) {
        this.selectedTab = tab;
      },
      getThirdComponentMobileHeight() {
        //if (window.innerWidth < 480) {
          if (this.selectedTab == 'three') {
            return { 'height':  this.thirdComponentMobileHeight + 'px' };
          } else {
            return "";
          }
        // } else {
        //   return "";
        // }
      },
      closePopup(){
        this.isBtnClicked = false
        if (this.selectedTab === "three"){
          this.selectedTab = "one";
        }
      }
    },
}
</script>

<style lang="scss" scoped>
.bg-1 {
    background-color: #8B40E2;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-scale-enter-active, .fade-scale-leave-active {
  transition: all .2s;
}
.fade-scale-enter, .fade-scale-leave-to {
  opacity: 0;
  transform: scale(0.5);
}
.pop-up-close-btn {
    background-color: #8B40E2;
    border-radius: 5px;
}
@responsive {
  .pop-up-div {
    max-height: calc(100vh - 220px);
    border: 1px solid #8B40E2;
    border-radius: 20px;
  }
  .pop-up-div-lg {
    width: 350px;
    // min-height: 520px;
    max-height: calc(100vh - 180px);
    border: 1px solid #8B40E2;
    border-radius: 18px;
  }
}

@responsive{
  .btr-20 {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }
  .btr-20-lg {
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
  }
  .f-12 {
    font-size: 12px;
  }
  .f-8 {
    font-size: 8px;
  }
  .ht-auto {
    height: auto;
  }
  .ht-200 {
    height: 200px;
  }
}
.z-51 {
  z-index: 51;
}
.bg-2 {
  background-color: #d1deef;
}
</style>