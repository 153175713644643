<template>
    <div class="flex flex-col py-16 px-4">
        <div class="container mx-auto">
            <h1 class="SofiaPro-Medium f-28 xs:f-48 text-center">Why Re:Set works?</h1>
            <div class="flex justify-around mt-16 flex-wrap sm:flex-no-wrap">
                <div class="w-100-per xs:w-230 md:w-320 flex flex-col items-center">
                    <div class="ht-120 xs:ht-160 wd-120 xs:wd-160">
                        <img class="h-full w-full rounded-full object-cover" :src="require('images/landing_page/section5/img1.png')" />
                    </div>
                    <h1 class="SofiaPro-Medium text-center text-black lin-ht-140 text-xl xs:text-2xl mt-8">Actionable</h1>
                    <p class="text-center text-color-1 lin-ht-140 mt-4">Solutions that work. <span class="SofiaPro-Medium text-color-2">100+ resources</span> on the platform and more added weekly.</p>
                </div>
                <div class="w-100-per xs:w-230 md:w-320 flex flex-col items-center mt-16 xs:mt-0">
                    <div class="ht-120 xs:ht-160 wd-120 xs:wd-160">
                        <img class="h-full w-full rounded-full object-cover" :src="require('images/landing_page/section5/img2.png')" />
                    </div>
                    <h1 class="SofiaPro-Medium text-center text-black lin-ht-140 text-xl xs:text-2xl mt-8">Proven</h1>
                    <p class="text-center text-color-1 lin-ht-140 mt-4"><span class="SofiaPro-Medium text-color-2">95% of our customers</span> have noticed a positive difference in their teams after just <span class="SofiaPro-Medium text-color-2" style="white-space: nowrap;">3 months of Re:Set</span>.</p>
                </div>
                <div class="w-100-per xs:w-230 md:w-320 flex flex-col items-center mt-16 xs:mt-0">
                    <div class="ht-120 xs:ht-160 wd-120 xs:wd-160">
                        <img class="h-full w-full rounded-full object-cover" :src="require('images/landing_page/section5/img3.png')" />
                    </div>
                    <h1 class="SofiaPro-Medium text-center text-black lin-ht-140 text-xl xs:text-2xl mt-8">Inclusive</h1>
                    <p class="text-center text-color-1 lin-ht-140 mt-4"><span class="SofiaPro-Medium text-color-2">8 languages</span> and counting. Services localized to suit hybrid and <span class="SofiaPro-Medium text-color-2">culturally diverse</span> environments.</p>
                </div>
            </div>
            <div class="flex justify-around mt-16 flex-wrap sm:flex-no-wrap">
                <div class="w-100-per xs:w-230 md:w-320 flex flex-col items-center">
                    <div class="ht-120 xs:ht-160 wd-120 xs:wd-160">
                        <img class="h-full w-full rounded-full object-cover" :src="require('images/landing_page/section5/img4.png')" />
                    </div>
                    <h1 class="SofiaPro-Medium text-center text-black lin-ht-140 text-xl xs:text-2xl mt-8">Flexible</h1>
                    <p class="text-center text-color-1 lin-ht-140 mt-4">From <span class="SofiaPro-Medium text-color-2">startups to unicorns</span>. Plans that cater to everyone.</p>
                </div>
                <div class="w-100-per xs:w-230 md:w-320 flex flex-col items-center mt-16 xs:mt-0">
                    <div class="ht-120 xs:ht-160 wd-120 xs:wd-160">
                        <img class="h-full w-full rounded-full object-cover" :src="require('images/landing_page/section5/img5.png')" />
                    </div>
                    <h1 class="SofiaPro-Medium text-center text-black lin-ht-140 text-xl xs:text-2xl mt-8">Fast</h1>
                    <p class="text-center text-color-1 lin-ht-140 mt-4">Well-being can’t wait. We launch your team’s mental health journey in <span class="SofiaPro-Medium text-color-2" style="white-space: nowrap;">as little as 2 weeks</span>.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "section5",
}
</script>

<style lang="scss" scoped>
@responsive {
    .w-320{
        width: 320px;
    }
    .w-230 {
        width: 230px;
    }
    .ht-160 {
        height: 160px;
    }
    .wd-160 {
        width: 160px;
    }
    .lin-ht-140 {
        line-height: 140%;
    }
    .ht-120 {
        height: 120px;
    }
    .wd-120 {
        width: 120px;
    }
    .f-48 {
        font-size: 48px;
    }
    .f-28 {
        font-size: 28px;
    }
    .w-100-per {
        width: 100%;
    }
}
.text-color-1 {
    color: #524F56;
}
.text-color-2 {
    color: #09040E;
}
</style>