<template>
  <div>
    <modal class="fs-modal static-modal-request-a-demo" :name="getModalName" :width="getWidth" :height="getHeight" scrollable="true" :adaptive="true" :clickToClose="false">
      <div class="h-full w-full flex flex-col relative">
        <div class="absolute right-0">
          <button
            title="Close"
            class="focus:outline-none absolute right-0 top-0 mr-4 mt-4 z-50"
            >
            <svg
              @click="closeModal"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="25"
              height="25"
              class="icon icon-remove"
              style="top: -5.5px; right: -15px"
              >
              <path
                d="M511.921231 0C229.179077 0 0 229.257846 0 512 0 794.702769 229.179077 1024 511.921231 1024 794.781538 1024 1024 794.702769 1024 512 1024 229.257846 794.781538 0 511.921231 0ZM732.041846 650.633846 650.515692 732.081231C650.515692 732.081231 521.491692 593.683692 511.881846 593.683692 502.429538 593.683692 373.366154 732.081231 373.366154 732.081231L291.761231 650.633846C291.761231 650.633846 430.316308 523.500308 430.316308 512.196923 430.316308 500.696615 291.761231 373.523692 291.761231 373.523692L373.366154 291.918769C373.366154 291.918769 503.453538 430.395077 511.881846 430.395077 520.349538 430.395077 650.515692 291.918769 650.515692 291.918769L732.041846 373.523692C732.041846 373.523692 593.447385 502.547692 593.447385 512.196923 593.447385 521.412923 732.041846 650.633846 732.041846 650.633846Z"
                fill="#8c40e2"
                ></path>
            </svg>
          </button>
        </div>
        <div class="w-full h-full flex flex-col md:flex-row-reverse flex-no-wrap md:flex-wrap">
          <div class="h-auto md:h-full w-full md:w-1/2 flex flex-col items-end">
            <img class="w-full md:w-auto object-cover md:object-contain ht-260 md:ht-auto" :src="require('images/landing_page/modals/request_a_demo/img1.png')" />
          </div>
          <div class="h-auto md:h-full w-full md:w-1/2 px-4 xs:px-8 md:px-10 flex-auto overflow-y-hidden">
            <form class="h-full" @submit.prevent="handleSubmit">
              <div class="h-full flex flex-col">
                <div class="flex justify-center mt-4 xs:mt-8 md:mt-16">
                  <h1 class="SofiaPro-Medium text-black text-xl xs:text-2xl lin-ht-30 xs:lin-ht-36 text-center lg:wd-525">Join leading organizations who use Re:Set to support their team’s well-being.</h1>
                </div>
                <div class="w-full flex-auto overflow-y-hidden xs:overflow-y-scroll pr-4 mt-4 xs:mt-8 md:mt-12">
                  <div class="flex flex-col">
                    <div class="flex flex-row flex-wrap">
                      <div class="w-full flex justify-start ht-56 rsd-input br-28 items-center">
                        <div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
                          <img :src="require('images/landing_page/modals/img1.svg')">
                        </div>
                        <div class="h-full flex-grow flex items-center pr-4">
                          <input class="input-btn outline-none text-sm w-full" v-model.trim="enteredName.val" placeholder="Name" @keyup="clearValidity('enteredName')" autocomplete="off" required />
                        </div>
                      </div>
                      <transition name="val-error">
                        <p v-if="!enteredName.isValid" class="text-xs text-red">
                          Name must not be empty.
                        </p>
                      </transition>
                      <div class="w-full mt-4 flex justify-start ht-56 rsd-input br-28 items-center">
                        <div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
                          <img :src="require('images/landing_page/modals/img2.svg')">
                        </div>
                        <div class="h-full flex-grow flex items-center pr-4">
                          <input class="input-btn outline-none text-sm w-full" v-model.trim="enteredEmail.val" placeholder="Email" @keyup="clearValidity('enteredEmail')" autocomplete="off" required />
                        </div>
                      </div>
                      <transition name="val-error">
                        <p v-if="!enteredEmail.isValid" class="text-xs text-red">
                          {{ enteredEmail.msg }}
                        </p>
                      </transition>
                      <div class="w-full mt-4 flex justify-start ht-56 rsd-input br-28 items-center">
                        <div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
                          <img :src="require('images/landing_page/modals/img3.svg')">
                        </div>
                        <div class="h-full flex-grow flex items-center pr-4">
                          <input class="input-btn outline-none text-sm w-full" v-model.trim="enteredOrganizationName.val" placeholder="Organization Name" @keyup="clearValidity('enteredOrganizationName')" autocomplete="off" required />
                        </div>
                      </div>
                      <transition name="val-error">
                        <p v-if="!enteredOrganizationName.isValid" class="text-xs text-red">
                          Organization Name must not be empty.
                        </p>
                      </transition>
                      <div class="w-full mt-4 flex justify-start ht-56 rsd-input br-28 items-center">
                        <div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
                          <img :src="require('images/landing_page/modals/img4.svg')">
                        </div>
                        <div class="h-full flex-grow flex items-center pr-4">
                          <input class="input-btn outline-none text-sm w-full" v-model.trim="enteredLocation.val" placeholder="Location" @keyup="clearValidity('enteredLocation')" autocomplete="off" required />
                        </div>
                      </div>
                      <transition name="val-error">
                        <p v-if="!enteredLocation.isValid" class="text-xs text-red">
                          Location Name must not be empty.
                        </p>
                      </transition>
                    </div>
                  </div>
                  <!-- <div class="flex flex-col mt-8 pl-4">
                    <h1 class="text-color-1 text-xs">Interests*</h1>
                    <div class="flex flex-row flex-wrap mt-2">
                    		<div class="w-full md:w-1/2 mb-4" v-for="(interest, index) in interests" :key="index">
                    				<div class="flex justify-start items-center">
                    						<div class="flex-shrink-0 mr-2">
                    								<img v-if="interest.isSelected" @click.stop="checkboxCilicked(index, false)" class="cursor-pointer" :src="require('images/landing_page/modals/checkbox_tick.svg')" />
                    								<img v-else @click.stop="checkboxCilicked(index, true)" class="cursor-pointer" :src="require('images/landing_page/modals/checkbox_untick.svg')" />
                    						</div>
                    						<div class="flex-grow text-black">{{ interest.text }}</div>
                    				</div>
                    		</div>
                    </div>
                    </div> -->
                  <div class="flex flex-col mt-8">
                    <h1 class="text-color-1 text-xs pl-4">Message*</h1>
                    <div class="flex flex-row flex-wrap mt-2">
                      <div class="w-full">
                        <div class="text-area-div p-4">
                          <textarea maxlength="250" class="h-full w-full text-sm outline-none resize-none" placeholder="Type your message here" v-model="enteredMessage.val" @keyup="clearValidity('enteredMessage')"/>
                        </div>
                        <transition name="val-error">
                          <p v-if="!enteredMessage.isValid" class="text-xs text-red">
                            Message must not be empty.
                          </p>
                        </transition>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col mt-8 mb-4">
                    <!-- <div class="g-recaptcha" :data-sitekey="recaptchaSiteKey" @verify="onRecaptchaVerified"></div> -->
                    <vue-recaptcha ref="recaptcha" sitekey="6Leo2XsoAAAAAAFCEztEMeL2uA5hyj22FLrN-846" @verify="onVerify" @expired="onExpired"></vue-recaptcha>
                  </div>
                </div>
                <!--  <div class="mt-8 mb-10 flex flex-col items-center">
                  <div class="g-recaptcha" data-sitekey="6LfzK3YoAAAAAEFxHhXE6fnK47p7cTDIdo2xcW"></div>
                  </div> -->
                <div class="mt-8 mb-10 flex flex-col items-center">
                  <div>
                    <transition name="val-error">
                      <p class="text-sm text-red mb-2" v-if="serverResponse.val === '0'">{{ serverResponse.msg }}</p>
                    </transition>
                  </div>
                  <button type="submit" name="button" :class="formIsValid == true && verified_user == true ? 'bg-1' : 'bg-darkgrey'" class="ht-56 w-100-per xs:wd-225 md:wd-309 lg:wd-389 br-28 flex justify-center items-center px-8 cursor-pointer">
                    <div class="flex justify-center SofiaPro-Medium text-white">Submit</div>
                    <div class="flex-shrink-0 pl-3">
                      <img :src="require('images/landing_page/modals/arrow.svg')" />
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
  import VueRecaptcha from 'vue-recaptcha';
  export default {
    name: "request-a-demo-modal",
    components: {
      VueRecaptcha
    },
    props: {
      page: String,
    },
    created() {
      setTimeout(() => {
        grecaptcha.render('recaptcha-main'); // 'recaptcha-main' is the id that was assigned to the widget
      }, 2000);
    },
    computed: {
      getModalName() {
        return `${this.page}-request-a-demo-modal`;
      },
      getWidth() {
        if (window.innerWidth > 1510) {
          return "1360px";
        } else {
          return '95%';
        }
      },
      getHeight() {
        if (window.innerWidth > 479) {
          return "85%";
        } else {
          return 'auto';
        }
      },
    },
    data() {
      return {
        enteredName: {
          val: "",
          isValid: true,
        },
        enteredEmail: {
          val: "",
          isValid: true,
          msg: "",
        },
        enteredOrganizationName: {
          val: "",
          isValid: true,
        },
        enteredLocation: {
          val: "",
          isValid: true,
        },
        enteredMessage: {
          val: "",
          isValid: true,
        },
        serverResponse: {
          val: "",
          msg: "",
        },
        formIsValid: true,
        verified_user: false,
        recaptchaSiteKey: "6LeydXkoAAAAALmeNG1lDzk9qbBW7hjiDflqxHkZ",
        interests: [{
            text: "Workplace well-being",
            isSelected: false,
          },
          {
            text: "Stress management",
            isSelected: false,
          },
          {
            text: "Good habits",
            isSelected: false,
          },
          {
            text: "COVID-19 support",
            isSelected: false,
          },
          {
            text: "Mental health management",
            isSelected: false,
          },
        ],
      };
    },
    methods: {
      closeModal() {
        this.clearInputFields();
        this.$modal.hide(`${this.page}-request-a-demo-modal`);
      },
      clearValidity(input) {
        this[input].isValid = true;
      },
      checkboxCilicked(index, status) {
        this.interests[index].isSelected = status;
      },
      validateForm() {
        this.formIsValid = true;
        this.serverResponse.val = "";
        if (this.enteredName.val === "") {
          this.enteredName.isValid = false;
          this.formIsValid = false;
        }
        if (this.enteredMessage.val === "") {
          this.enteredMessage.isValid = false;
          this.formIsValid = false;
        }
        if (this.enteredOrganizationName.val === "") {
          this.enteredOrganizationName.isValid = false;
          this.formIsValid = false;
        }
        if (this.enteredLocation.val === "") {
          this.enteredLocation.isValid = false;
          this.formIsValid = false;
        }
        if (this.enteredEmail.val === "") {
          this.enteredEmail.isValid = false;
          this.enteredEmail.msg = "Email must not be empty.";
          this.formIsValid = false;
        } else {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(this.enteredEmail.val)) {
            this.enteredEmail.isValid = false;
            this.enteredEmail.msg = "You have entered an invalid email address!";
            this.formIsValid = false;
          }
        }
      },
      handleSubmit() {
        this.validateForm();
        if (!this.formIsValid) {
          return;
        }

        const interests = [];

        this.interests.forEach((item) => {
          if (item.isSelected) {
            interests.push(item.text);
          }
        });

        const data = {
          name: this.enteredName.val,
          email: this.enteredEmail.val,
          organization_name: this.enteredOrganizationName.val,
          location: this.enteredLocation.val,
          interests: interests,
          message: this.enteredMessage.val,
        };
        fetch("/request_a_demo", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
          .then((response) => response.json())
          .then((data) => {
            this.serverResponse.val = data.code;
            this.serverResponse.msg = data.msg;
            if (data.code == "1") {
              this.$swal({
                title: '',
                text: 'Thank you for reaching out. We’ll be in touch with you shortly!',
                type: 'success',
                showConfirmButton: true,
              });
              this.$modal.hide(`${this.page}-request-a-demo-modal`);
              this.clearInputFields();
            } else {
              this.serverResponse.val = "0";
            }
          })
          .catch((error) => {
            this.serverResponse.val = "0";
            this.serverResponse.msg = error;
          });
      },
      clearInputFields() {
        this.enteredName.val = "";
        this.enteredOrganizationName.val = "";
        this.enteredLocation.val = "";
        this.enteredEmail.val = "";
        this.enteredMessage.val = "";
        this.serverResponse.val = "";
        this.serverResponse.msg = "";
        this.interests.forEach((item, index) => {
          if (item.isSelected) {
            this.interests[index].isSelected = false;
          }
        });
      },

      onVerify(response) {
        // console.log('Verify: ' + response)
        if (response) {
          this.verified_user = true
        }
      },

      onExpired() {
        this.verified_user = false
      }
    }
  } 
</script>

<style lang="scss" scoped>.fs-modal {
  background-color: #000000d6;
}

.rsd-input {
  border: 1px solid #ECECEC;
  background-color: white;
}

.input-btn {
  outline: 0;
}

.br-28 {
  border-radius: 28px;
}

.bg-1 {
  background: #8B40E2;
}

.text-area-div {
  height: 180px;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 28px;
}

.val-error-enter-active,
.val-error-leave-active {
  transition: opacity 1s ease;
}

.val-error-enter,
.val-error-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.text-color-1 {
  color: #848186;
}

@responsive {
  .wd-525 {
    width: 525px;
  }

  .ht-56 {
    height: 56px;
  }

  .wd-389 {
    width: 389px;
  }

  .wd-309 {
    width: 309px;
  }

  .wd-225 {
    width: 225px;
  }

  .ht-260 {
    height: 260px;
  }

  .ht-auto {
    height: auto;
  }

  .lin-ht-36 {
    line-height: 36px;
  }

  .lin-ht-30 {
    line-height: 30px;
  }

  .w-100-per {
    width: 100%;
  }
}

</style><style lang="scss">.v--modal-box.v--modal {
  border-radius: 12px;
}

@media only screen and (max-width: 480px) {
  .static-modal-request-a-demo {
    .v--modal-background-click {
      .v--modal-box.v--modal {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
      }
    }
  }
}

</style>