<template>
    <div id="lets-go" class="flex flex-col py-16 px-4">
        <div class="container mx-auto">
            <div class="w-full flex justify-center">
                <h1 class="SofiaPro-Medium f-24 xs:f-48 lin-ht-140 text-center text-color-1 sm:w-700">What will your Re:Set Employee <span style="white-space: nowrap;">Well-Being</span> Program look like?</h1>
            </div>
            <div class="w-full flex justify-center">
                <p class="text-xs xs:text-xl text-center lin-ht-140 text-color-2 sm:w-700">The <span style="white-space: nowrap;">A-Z</span> of <span style="white-space: nowrap;">well-being</span> and everything in between. Speak to a Re:Set expert to get a bespoke program that suits your needs.</p>
            </div>
            <div class="flex flex-row flex-wrap mt-4 xs:mt-16">
                <div class="w-full xs:w-1/2 sm:w-1/3 flex justify-start">
                    <div class="flex flex-col w-full items-center">
                        <div class="ht-auto xs:ht-150 md:ht-200 lg:ht-250 flex items-center">
                            <img :src="require('images/landing_page/section9/img1.svg')" class="w-full ht-125 xs:ht-150 md:ht-200 lg:ht-250">
                        </div>
                        <div class="wd-100-per sm:w-225 md:w-280">
                            <p class="text-center text-color-1 lin-ht-140 leading-tight"><span style="white-space: nowrap;">Self-Paced</span> Guides for Building Healthy and Positive Habits</p>
                        </div>
                    </div>
                </div>
                <div class="w-full xs:w-1/2 sm:w-1/3 flex justify-end sm:justify-center mt-8 xs:mt-0">
                    <div class="flex flex-col w-full items-center">
                        <div class="ht-auto xs:ht-150 md:ht-200 lg:ht-250 flex items-center">
                            <img :src="require('images/landing_page/section9/img2.svg')" class="w-full ht-125 xs:ht-150 md:ht-200 lg:ht-250">
                        </div>
                        <div class="wd-100-per sm:w-230 md:w-260 lg:w-290">
                            <p class="text-center text-color-1 lin-ht-140 leading-tight"><span style="white-space: nowrap;">Bite-Sized</span> Therapeutic Content for Emotional Wellness</p>
                        </div>
                    </div>
                </div>
                <div class="w-full xs:w-1/2 sm:w-1/3 flex justify-start sm:justify-end mt-12 xs:mt-4 sm:mt-0">
                    <div class="flex flex-col w-full items-center">
                        <div class="ht-auto xs:ht-150 md:ht-200 lg:ht-250 flex items-center">
                            <img :src="require('images/landing_page/section9/img3.svg')" class="w-full ht-100 xs:ht-125 md:ht-150 lg:ht-200">
                        </div>
                        <div class="wd-100-per sm:w-210 md:w-240 lg:w-280 mt-3 xs:mt-0">
                            <p class="text-center text-color-1 lin-ht-140 leading-tight">Interactive and Informative Short and <span style="white-space: nowrap;">Long-Form</span> Videos</p>
                        </div>
                    </div>
                </div>
                <div class="w-full xs:w-1/2 sm:w-1/3 flex justify-end sm:justify-start mt-4 sm:mt-8">
                    <div class="flex flex-col w-full items-center">
                        <div class="ht-auto xs:ht-175 md:ht-200 lg:ht-250 flex items-center">
                            <img :src="require('images/landing_page/section9/img4.svg')" class="w-full ht-200 xs:ht-225 md:ht-250 lg:ht-300">
                        </div>
                        <div class="wd-100-per sm:wd-100-per md:w-315 lg:w-350 -mt-12 xs:mt-0">
                            <p class="text-center text-color-1 lin-ht-140 leading-tight">From <span style="white-space: nowrap;">C-Suite</span> to Support Staff: Customized Workshops and Coaching to Support Employee <span style="white-space: nowrap;">Well-Being</span></p>
                        </div>
                    </div>
                </div>
                <div class="w-full xs:w-1/2 sm:w-1/3 flex justify-start sm:justify-center mt-10 xs:mt-4 sm:mt-8">
                    <div class="flex flex-col w-full items-center">
                        <div class="ht-auto xs:ht-175 md:ht-200 lg:ht-250 flex items-center">
                            <img :src="require('images/landing_page/section9/img5.svg')" class="w-full ht-100 xs:ht-125 md:ht-150 lg:ht-200">
                        </div>
                        <div class="w-165 md:w-210">
                            <p class="text-center text-color-1 lin-ht-140 leading-tight">Scientific Assessments and Trackers</p>
                        </div>
                    </div>
                </div>
                <div class="w-full xs:w-1/2 sm:w-1/3 flex justify-end mt-10 xs:mt-4 sm:mt-8">
                    <div class="flex flex-col w-full items-center">
                        <div class="ht-auto xs:ht-175 md:ht-200 lg:ht-250 flex items-center">
                            <img :src="require('images/landing_page/section9/img6.svg')" class="w-full ht-100 xs:ht-125 md:ht-150 lg:ht-200">
                        </div>
                        <div class="wd-100-per md:w-310">
                            <p class="text-center text-color-1 lin-ht-140 leading-tight">Comprehensive, Bespoke and Culturally Sensitive Resources, Designed by <span style="white-space: nowrap;">World-Class</span> Experts</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "section9",
}
</script>

<style lang="scss" scoped>
@responsive {
    .w-700 {
        width: 700px;
    }
    .lin-ht-140 {
        line-height: 140%;
    }
    .f-48 {
        font-size: 48px;
    }
    .f-28 {
        font-size: 28px;
    }
    .f-24 {
        font-size: 24px;
    }
    .w-280 {
        width: 280px;
    }
    .w-290 {
        width: 290px;
    }
    .w-350 {
        width: 350px;
    }
    .w-210 {
        width: 210px;
    }
    .w-310 {
        width: 310px;
    }
    .ht-auto {
        height: auto;
    }
    .ht-250 {
        height: 250px;
    }
    .w-260 {
        width: 260px;
    }
    .w-240 {
        width: 240px;
    }
    .w-315 {
        width: 315px;
    }
    .ht-300 {
        height: 300px;
    }
    .ht-350 {
        height: 350px;
    }
    .ht-200 {
        height: 200px;
    }
    .wd-100-per {
        width: 100%;
    }
    .w-225 {
        width: 225px;
    }
    .w-230 {
        width: 230px;
    }
    .w-165 {
        width: 165px;
    }
    .ht-150 {
        height: 150px;
    }
    .ht-125 {
        height: 125px;
    }
    .ht-225 {
        height: 225px;
    }
    .ht-175 {
        height: 175px;
    }
    .ht-100 {
        height: 100px;
    }
}
.text-color-1 {
    color: #09040E;
}
.text-color-2 {
    color: #524F56;
}
.text-color-3 {
    color: #524f56a8;
}
</style>