<template>
    <div id="how-we-work" class="flex flex-col py-16 px-4">
        <div class="container mx-auto">
            <h1 class="SofiaPro-Medium f-28 xs:f-48 text-center text-color-1">How does it work?</h1>
            <div class="flex flex-row flex-wrap mt-12 xs:mt-16">
                <div class="w-full xs:w-1/2 sm:w-1/3 flex justify-start">
                    <div class="flex flex-col items-center w-full xs:w-214 sm:w-230 md:w-300 lg:w-346 relative">
                        <div class="absolute left-0 top--35">
                            <img :src="require('images/landing_page/section3/1.svg')">
                        </div>
                        <img class="w-full xs:w-auto br-24" :src="require('images/landing_page/section3/img1.png')">
                        <h1 class="text-center text-xl text-color-1 lin-ht-140 mt-8">Re:Set Diagnostic Assessment</h1>
                        <p class="text-center text-color-2 lin-ht-140 mt-4">Re:Set will conduct our proprietary organizational well-being assessment to determine employee sentiment and identify areas of support and growth to help you mitigate workforce challenges.</p>
                    </div>
                </div>
                <div class="w-full flex xs:hidden justify-center my-8">
                    <img :src="require('images/landing_page/section3/down_arrow.svg')">
                </div>
                <div class="w-full xs:w-1/2 sm:w-1/3 flex justify-end sm:justify-center">
                    <div class="flex flex-col items-center w-full xs:w-214 sm:w-230 md:w-300 lg:w-346 relative">
                        <div class="absolute left-0 top--35">
                            <img :src="require('images/landing_page/section3/2.svg')">
                        </div>
                        <img class="w-full xs:w-auto br-24" :src="require('images/landing_page/section3/img2.png')">
                        <h1 class="text-center text-xl text-color-1 lin-ht-140 mt-8 px-4">Ignite data-driven solutions</h1>
                        <p class="text-center text-color-2 lin-ht-140 mt-4">Re:Set creates a bespoke and scientifically developed employee well-being program with insights from our diagnostic assessment to improve company culture, employee retention and engagement.</p>
                    </div>
                </div>
                <div class="w-full flex xs:hidden justify-center my-8">
                    <img :src="require('images/landing_page/section3/down_arrow.svg')">
                </div>
                <div class="w-full xs:w-1/2 sm:w-1/3 flex justify-start sm:justify-end mt-0 xs:mt-8 sm:mt-0">
                    <div class="flex flex-col items-center w-full xs:w-214 sm:w-230 md:w-300 lg:w-346 relative">
                        <div class="absolute left-0 top--35">
                            <img :src="require('images/landing_page/section3/3.svg')">
                        </div>
                        <img class="w-full xs:w-auto br-24" :src="require('images/landing_page/section3/img3.png')">
                        <h1 class="text-center text-xl text-color-1 lin-ht-140 mt-8">Sit back and watch your team thrive</h1>
                        <!-- <p class="text-center text-color-2 lin-ht-140 mt-4 px-4">Your workplace wellness program goes live in as little as 2 weeks from <span class="text-center text-color-2 lin-ht-140" style="white-space: nowrap;">sign-off.</span></p> -->
                        <p class="text-center text-color-2 lin-ht-140 mt-4">Your employee wellness program goes live in as little as 2 weeks from sign-off. Build a mentally fit, productive and motivated workforce through our practical and psychologically-designed workplace solutions.</p>
                    </div>
                </div>
            </div>
            <div class="flex justify-center items-center mt-16 flex-wrap xs:flex-no-wrap">
                <div @click.stop="scrollToElement('#lets-go', -88)" class="ht-56 w-100-per xs:wd-240 bg-white br-28 bor-line cursor-pointer text-color-3 flex items-center justify-center mr-0 xs:mr-4">Let’s go!</div>
                <div @click="openRequestADemoPopUp" class="flex items-center justify-center ht-56 w-100-per xs:wd-240 bg-1 br-28 cursor-pointer mt-2 xs:mt-0">
                    <div class="text-white">Book a session</div>
                    <div class="ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "section3",
    data() {
        return {
            modalPageName: "header",
        };
    },
    methods: {
        scrollToElement(selector, yOffset = 0) {
            const el = document.querySelector(selector);
            if (el) {
                const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }       
        },
        openRequestADemoPopUp() {
            this.$modal.show(`${this.modalPageName}-request-a-demo-modal`);
        },
    }
}
</script>

<style lang="scss" scoped>
@responsive {
    .w-346 {
        width: 346px;
    }
    .w-300 {
        width: 300px;
    }
    .w-230 {
        width: 230px;
    }
    .w-214 {
        width: 214px;
    }
    .lin-ht-140 {
        line-height: 140%;
    }
    .ht-56 {
        height: 56px;
    }
    .wd-240 {
        width: 240px;
    }
    .f-48 {
        font-size: 48px;
    }
    .f-28 {
        font-size: 28px;
    }
    .top--35 {
        top: -35px;
    }
    .w-100-per {
        width: 100%;
    }
}
.text-color-1 {
    color: #09040E;
}
.text-color-2 {
    color: #524F56;
}
.text-color-3 {
    color: #8B40E2;
}
.bg-1 {
    background: #8B40E2;
}
.br-28 {
    border-radius: 28px;
}
.bor-line {
    border: 1px solid #DCC6F6;
}
.br-24 {
    border-radius: 24px;
}
</style>