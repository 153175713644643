<template>
    <div class="flex flex-col py-16 px-4">
        <div class="container mx-auto">
            <div class="flex justify-start items-center flex-wrap sm:flex-no-wrap">
                <div class="flex-grow sm:flex-shrink-0 mr-0 sm:mr-16">
                    <h1 class="text-color-1 text-base xs:text-xl lin-ht-140 text-center sm:text-left">As Featured In</h1>
                </div>
                <div class="flex-grow overflow-x-hidden flex flex-col">
                    <div class="w-full">
                        <div class="slider">
                            <div class="slide-track">
                                <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_1.png')" />
                                </div>
                                <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_2.png')" />
                                </div>
                                <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_3.png')" />
                                </div>
                                <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_4.png')" />
                                </div>
                                <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_5.png')" />
                                </div>
                                 <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_6.png')" />
                                </div>
                                 <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_7.png')" />
                                </div>
                                 <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_8.png')" />
                                </div>
                                <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_1.png')" />
                                </div>
                                <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_2.png')" />
                                </div>
                                <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_3.png')" />
                                </div>
                                <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_4.png')" />
                                </div>
                                <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_5.png')" />
                                </div>
                                 <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_6.png')" />
                                </div>
                                 <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_7.png')" />
                                </div>
                                 <div class="slide">
                                    <img :src="require('images/landing_page/section6/img_8.png')" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "section6",
}
</script>

<style lang="scss" scoped>
@responsive {
    .lin-ht-140 {
        line-height: 140%;
    }
}
.text-color-1 {
    color: #09040E;
}
.slider {
    height: 250px;
    margin: auto;
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
}
.slide-track {
    display: flex;
    width: calc(250px * 10);
    animation: scroll 40s linear infinite;
}
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 5));
    }
}
.slide {
    height: 200px;
    width: 250px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
}
img {
    width: 100%;
    transition: transform 1s;
}
img:hover {
    transform: translateZ(20px);
}
.slider::before,
.slider::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;
}
.slider::before {
    left: 0;
    top: 0;
}
.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}
</style>