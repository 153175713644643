<template>
    <div class="flex flex-col py-16 px-4">
        <div class="container mx-auto">
            <div class="sm:ht-440 bg-1 br-24 flex flex-col items-center justify-center relative">
                <div class="w-full absolute top-0">
                    <img class="ht-74 sm:ht-53 md:ht-84 absolute right-2-rem top--16 md:top--28" :src="require('images/landing_page/section8/img1.png')" />
                </div>
                <div class="flex flex-col items-center sm:w-700 md:w-914 px-4 sm:px-0">
                    <h1 class="SofiaPro-Medium text-white f-24 xs:f-32 sm:f-40 lin-ht-130 text-center mt-24 sm:mt-0">Join leading organizations who use Re:Set to support their team’s well-being</h1>
                    <div @click="openRequestADemoPopUp" class="mt-8 ht-56 w-100-per xs:wd-360 br-28 flex justify-center items-center bg-white px-8 cursor-pointer">
                        <div class="flex justify-center SofiaPro-Medium">Request a demo</div>
                        <div class="flex-shrink-0 pl-2">
                            <img :src="require('images/defaults/right_arrow_2.svg')" />
                        </div>
                    </div>
                </div>
                <div class="w-full static sm:absolute bottom-0">
                    <img class="ht-80 sm:ht-70 md:ht-112 static sm:absolute bottom-2-rem mt-16 sm:mt-0 mb-16 sm:mt-0" :src="require('images/landing_page/section8/img2.png')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "section8",
    data() {
        return {
            modalPageName: "header",
        };
    },
    methods: {
        openRequestADemoPopUp() {
            this.$modal.show(`${this.modalPageName}-request-a-demo-modal`);
        },
    },
}
</script>

<style lang="scss" scoped>
@responsive {
    .ht-440 {
        height: 440px;
    }
    .ht-84 {
        height: 84px;
    }
    .right-2-rem {
        right: 2rem;
    }
    .top--28 {
        top: -28px;
    }
    .top--16 {
        top: -16px;
    }
    .f-40 {
        font-size: 40px;
    }
    .f-32 {
        font-size: 32px;
    }
    .f-24 {
        font-size: 24px;
    }
    .lin-ht-130 {
        line-height: 130%;
    }
    .w-914 {
        width: 914px;
    }
    .w-700 {
        width: 700px;
    }
    .ht-56 {
        height: 56px;
    }
    .wd-360 {
        width: 360px;
    }
    .w-100-per {
        width: 100%;
    }
    .bottom-2-rem {
        bottom: 2rem;
    }
    .ht-112 {
        height: 112px;
    }
    .ht-53 {
        height: 53px;
    }
    .ht-70 {
        height: 70px;
    }
    .ht-74  {
        height: 74px;
    }
    .ht-80 {
        height: 80px;
    }
}
.bg-1 {
    background: #8B40E2;
}
.br-24 {
    border-radius: 24px;
}
.br-28 {
    border-radius: 28px;
}
</style>