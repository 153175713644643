<template>
  <div class="flex flex-col bg-white min-h-screen">
      <page-header :hide_navbar="hide_navbar"></page-header>
      <!-- <discount-band :hide_navbar="hide_navbar"></discount-band> -->
      <section1></section1>
      <section2></section2>
      <section11></section11>
      <section10></section10>
      <section3></section3>
      <section9></section9>
      <section5></section5>
      <section12></section12>
      <section6></section6>
      <section13></section13>
      <section7></section7>
      <section8></section8>
      <page-footer></page-footer>
      <fixed-contact-us-pop-up></fixed-contact-us-pop-up>
      <opt-in-pop-up v-if="showPopup"></opt-in-pop-up>
      <!-- <free-access-popup v-if="show_free_access === true"></free-access-popup> -->
  </div>
</template>
<script>
import Section1 from "./sections/landing_page/section1.vue";
import Section2 from "./sections/landing_page/section2.vue";
import Section3 from "./sections/landing_page/section3.vue";
import Section9 from "./sections/landing_page/section9.vue";
import Section5 from "./sections/landing_page/section5.vue";
import Section6 from "./sections/landing_page/section6.vue";
import Section7 from "./sections/landing_page/section7.vue";
import Section8 from "./sections/landing_page/section8.vue";
import Section10 from "./sections/landing_page/section10.vue";
import PageHeader from "./components/header.vue";
import PageFooter from "./components/footer.vue";
import FixedContactUsPopUp from "./components/fixed_contact_us_pop_up.vue";
import OptInPopUp from "./modals/opt_in_popup.vue";
import FreeAccessPopup from "./modals/free_access_popup.vue";
import DiscountBand from "./components/discount_band.vue";
import Section11 from "./sections/landing_page/section11.vue";
import Section12 from "./sections/landing_page/section12.vue";
import Section13 from "./sections/landing_page/section13.vue";

export default {
    name: "landing-page",
    props: {
        hide_navbar: String,
    },
    data() {
        return {
            loginLink: "users/login",
            registerLink: "users/register",
            show_free_access: false,
            showPopup: false,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        if (window.location.hash === '#how-we-works') {
          setTimeout(() => {
              const yOffset = -88;
              const el = document.querySelector('#how-we-work');
              if (el) {
                  const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
                  window.scrollTo({top: y, behavior: 'smooth'});
              }
          }, 500);
          setTimeout(() => {
              history.pushState("", document.title, window.location.pathname + window.location.search);
          }, 10);
        }else if(window.location.hash === '#lets-go'){
          setTimeout(() => {
              const yOffset = -120;
              const el = document.querySelector('#lets-go');
              if (el) {
                  const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
                  window.scrollTo({top: y, behavior: 'smooth'});
              }
          }, 500);
          setTimeout(() => {
              history.pushState("", document.title, window.location.pathname + window.location.search);
          }, 10);
        }
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      handleScroll() {
          // Calculate the scroll position as a percentage of the page height
          const scrollY = window.scrollY;
          const pageHeight = document.documentElement.scrollHeight;
          const scrollPercentage = (scrollY / pageHeight) * 100;

          // You can adjust the threshold (50) as needed
          if (scrollPercentage >= 50) {
            var is_modal_show = this.getSessionStorageWithExpiry('alreadyShowOptIn')
            if(is_modal_show == null){
              this.showPopup = true;
            };
          } else {
            this.showPopup = false;
          }
      },

      getSessionStorageWithExpiry(key) {
        const itemStr = sessionStorage.getItem(key);
        if (itemStr == null) {
          return null; // Key not found in session storage
        };
        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiry) {
          sessionStorage.removeItem(key); // Remove the item if it's expired
          return null;
        }
        return item.value;
      }
    },
    created(){
      // setTimeout(()=>{
      //   let now = new Date();
      //   let storedData = sessionStorage.getItem('nudgeShowFreeAccess');
      //   let already_signup = sessionStorage.getItem('alreadyShowFreeAccess');
      //   if (storedData) {
      //     let parsedData = JSON.parse(storedData)
      //     if ((parsedData.expiryTime && parsedData.expiryTime < now.getTime()) && (already_signup != 'alreadyshownfreeaccess')) {
      //       sessionStorage.removeItem('nudgeShowFreeAccess');
      //       this.show_free_access = true;
      //     }
      //   }else{
      //     this.show_free_access = true;
      //   }
      // },3000)
    },
    components: { PageHeader, Section1, Section2, Section3, Section9, Section5, Section6, Section8, Section10, PageFooter, Section7, FixedContactUsPopUp, FreeAccessPopup, DiscountBand, OptInPopUp, Section11,Section12, Section13},
}
</script>
<style lang="scss" scoped>

</style>