<template>
  <div class="w-full bg-1">
    <div v-if="hide_navbar === 'show'" class="hidden sm:w-full md:w-full lg:w-full xl:large-width ht-44 bg-1 sm:flex md:flex lg:flex items-center z-50 fixed cursor-pointer" @click.prevent="openRequestADemoPopUp()" style="border-bottom-left-radius: 14px; border-bottom-right-radius: 14px;">
      <div class="container mx-auto"> 
        <div class="flex flex-wrap justify-center items-center">
          <div class="flex pr-1 ">
            <p class="text-color-1 font-light">Time to take action! Enjoy <span class="text-color-2 font-semibold">a 10% discount</span> on all our well-being services for a limited period.</p>
          </div>
          <div>
            <img class="h-6 w-6" :src="require('images/disc_band_icon.svg')">
          </div>
        </div>
      </div>
    </div>
    <!-- request a demo modal start -->
    <request-a-demo :page="modalPageName"></request-a-demo>
    <!-- request a demo modal end -->
  </div>
</template>

<script>
import RequestADemo from "../modals/request_a_demo.vue";

export default {
    name: "discount_popup",
    components: { RequestADemo},
    props: {
        hide_navbar: String,
    },
    data() {
        return {
          modalPageName: "header",
        };
    },
    methods: {
      openRequestADemoPopUp() {
        this.$modal.show(`${this.modalPageName}-request-a-demo-modal`);
      },
    },
}
</script>

<style lang="scss" scoped>
.text-color-1 {
  color: #36598d;
  font-size: 17px;
/*  font-family: Sofia Pro Light;*/
}

.text-color-2{
  font-size: 17px;
  color: #36598d;
}
.bg-1 {
  background: #94cbdb;
}
.br-28 {
  border-radius: 28px;
}

.large-width{
  width:65.5%
}
@responsive {
  .ht-44 {
      height: 44px;
  }
  .ht-56 {
      height: 56px;
  }
  .wd-200 {
      width: 200px;
  }
  .ht-36 {
    height: 36px;
  }

  .large-width{
    width:65.5%
  }
}
</style>