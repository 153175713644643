<template>
    <div class="flex flex-col py-16 pt-0 px-4">
        <div class="container mx-auto">
            <h1 class="SofiaPro-Medium f-28 xs:f-48 text-center">Re:Set Resources</h1>
            <div class="w-full flex flex-col mt-16">
                    <VueSlickCarousel @beforeChange="beforeChange" class="testimonials" v-if="!isMobile" v-bind="settings">
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/handling-anxiety-attacks">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/do.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">Dos and Don'ts When Someone Is Having an Anxiety Attack</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/deal-with-upsetting-thoughts">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/how.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">How to Deal With Upsetting Thoughts</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/toxic-relationships">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/toxic.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">Recognizing the Signs of a Toxic Relationship and Their Impact on Our Mental Health</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/how-to-avoid-ghosting">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/ghosting.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">How to Avoid Ghosting And Have An Awkward Conversation Like An Adult</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/founders-depression">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/dark_side.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">The Dark Side of Entrepreneurship: Founder's Depression Five Ways to Attract and Retain the Best Talent in Your Organization Level Up: How Goal Setting Games Can Improve Your Productivity</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/attract-and-retain-best-talent">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/five_ways.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">Five Ways to Attract and Retain the Best Talent in Your Organization</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/goal-setting-productivity-games">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/level_up.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">Level Up: How Goal Setting Games Can Improve Your Productivity</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/micro-workouts-busy-lifestyle">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/sneaking.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;"/>

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">How Sneaking In Micro Workouts Into Hectic Workdays Can Help You Get Fitter</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/healthy-bedtime-routine">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/bedtime.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">The Re:Set Guide To Building a Phone-Free Nighttime Routine</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/understanding-emotional-wellness">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/emotional.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">Understanding Emotional Wellness and How to Cultivate It in Your Life</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/sleep-nutrition-mental-health">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/train_brain.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">How To Train Your Brain To Eat Well and Rest Better</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                    </VueSlickCarousel>

                    <VueSlickCarousel @beforeChange="beforeChange" class="testimonials" v-if="isMobile" v-bind="mobileSettings">
                       <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/handling-anxiety-attacks">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/do.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">Dos and Don'ts When Someone Is Having an Anxiety Attack</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/deal-with-upsetting-thoughts">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/how.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">How to Deal With Upsetting Thoughts</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/toxic-relationships">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/toxic.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">Recognizing the Signs of a Toxic Relationship and Their Impact on Our Mental Health</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/how-to-avoid-ghosting">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/ghosting.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">How to Avoid Ghosting And Have An Awkward Conversation Like An Adult</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/founders-depression">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/dark_side.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">The Dark Side of Entrepreneurship: Founder's Depression Five Ways to Attract and Retain the Best Talent in Your Organization Level Up: How Goal Setting Games Can Improve Your Productivity</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/attract-and-retain-best-talent">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/five_ways.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">Five Ways to Attract and Retain the Best Talent in Your Organization</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/goal-setting-productivity-games">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/level_up.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">Level Up: How Goal Setting Games Can Improve Your Productivity</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/micro-workouts-busy-lifestyle">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/sneaking.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;"/>

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">How Sneaking In Micro Workouts Into Hectic Workdays Can Help You Get Fitter</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/healthy-bedtime-routine">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/bedtime.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">The Re:Set Guide To Building a Phone-Free Nighttime Routine</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/understanding-emotional-wellness">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/emotional.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">Understanding Emotional Wellness and How to Cultivate It in Your Life</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        <a class="px-6" target="_blank" href="https://yourdailyreset.com/resources/sleep-nutrition-mental-health">
                          <div class="fs-white-box p-1 hosted-card mt-0 mb-4 overflow-hidden fs-hover-outline-purple flex flex-col bg-1">
                            <img class="w-full cover-image rounded-xl" :src="require('images/landing_page/section13/train_brain.jpeg')" style="border-top-left-radius: 16px; border-top-right-radius: 16px;" />

                            <div class="flex-auto flex flex-col overflow-y-hidden ">
                              <div class="p-4 h-full flex flex-col justify-between overflow-y-hidden">
                                <div class="flex-auto flex flex-col overflow-y-hidden"> 
                                  <div class="flex flex-col ht-100 flex-shrink-0">  
                                    <p class="text-black text-lg truncate-text-3 mt-1">How To Train Your Brain To Eat Well and Rest Better</p> 
                                  </div>  
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                    </VueSlickCarousel>

                    <div class="mt-8 flex justify-center items-center">
                        <div class="flex-shrink-0 mr-2">
                            <img @click.stop="prevSlide" class="cursor-pointer" :src="require('images/landing_page/section7/arrow_left.svg')" />
                        </div>
                        <div class="flex-shrink-0 ml-2">
                            <img @click.stop="nextSlide" class="cursor-pointer" :src="require('images/landing_page/section7/arrow_right.svg')" />
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
    name: "section5",
    components: { VueSlickCarousel, },
    data() {
        return {
          settings: {
            "centerMode": true,
            "arrows": true,
            "dots": false,
            "infinite": true,
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "speed": 500,
            "touchThreshold": 3,
            "variableWidth": true,
            "cssEase": "linear",
          },
          slideIndex: 1,
          isMobile: false,
          mobileSettings: {
            "dots": false,
            "arrows": true,
            "dotsClass": "slick-dots custom-dot-class",
            "edgeFriction": 0.35,
            "infinite": true,
            "speed": 500,
            "slidesToShow": 1,
            "slidesToScroll": 1,
          },
        };
    },
    mounted() {
        if(window.innerWidth > 767) {
            this.isMobile = false;
        } else {
            this.isMobile = true;
        }
    },
    methods: {
        nextSlide() {
        const sliderDiv = document.querySelector(".testimonials");
        sliderDiv.lastChild.click();
        },
        prevSlide() {
        const sliderDiv = document.querySelector(".testimonials");
        sliderDiv.firstChild.click();
        },
        beforeChange(oldSlideIndex, newSlideIndex) {
        this.slideIndex = newSlideIndex + 1;
        },
    },
}
</script>

<style lang="scss" scoped>
  img.cover-image {
    height: 180px;
    object-fit: cover;
  }

  .hidden{
    display: none !important
  }

  .hosted-card{
    max-width: 310px !important;
    height: 330px !important;
    width: 100%;
    border-radius: 25px;
  }

  .text-card{
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .bg-1{
    background-color: #f4f7fa !important;
  }

  .fs-hover-outline-purple:hover {
    box-shadow: inset 0px 0px 0px 1px #8c40e2 !important;
  }

  @responsive {
    .ht-100 {
      height: 100px;
    }

    .f-48 {
        font-size: 48px;
    }
    .f-28 {
        font-size: 28px;
    }
  }
</style>

<style lang="scss">
  .testimonials {
    .slick-arrow {
      display: none !important;
    }
  }
</style>