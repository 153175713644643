<template>
    <div class="flex flex-col py-16 px-4">
        <div class="container mx-auto">
            <h1 class="SofiaPro-Medium f-28 xs:f-48 text-center">What are they saying?</h1>
            <div class="w-full flex flex-col mt-16">
                    <VueSlickCarousel @beforeChange="beforeChange" class="testimonials" v-if="testimonials.length > 0 && !isMobile" v-bind="settings">
                        <div v-for="(testimonial, index) in testimonials" :key="testimonial.id" :class="index == slideIndex - 1 ? 'bg-1' : 'bg-2'" class="flex flex-col br-24 card xs:card-xs sm:card-sm md:card-md lg:card-lg mx-2 sm:mx-4 md:mx-6">
                            <div class="min-ht-454 flex flex-col justify-between p-8">
                                <p :class="index == slideIndex - 1 ? 'text-black' : 'text-color-2'" class="text-base xs:text-xl lin-ht-160" v-html="testimonial.quote"></p>
                                <div class="flex justify-start items-center flex-wrap xs:flex-no-wrap mt-0 sm:mt-4 md:mt-0">
                                    <div class="flex-shrink-0 mr-4">
                                        <img class="ht-90 wd-90 rounded-full" :class="testimonial.isDynamicStyle ? 'object-contain bg-white' : 'object-cover'" :src="testimonial.image" />
                                    </div>
                                    <div class="flex flex-col">
                                        <h1 class="SofiaPro-Medium">{{ testimonial.name }}</h1>
                                        <p class="text-color-1 text-sm">{{ testimonial.designation }}</p>
                                        <p class="text-color-1 text-sm">{{ testimonial.organization }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </VueSlickCarousel>

                    <VueSlickCarousel @beforeChange="beforeChange" class="testimonials" v-if="testimonials.length > 0 && isMobile" v-bind="mobileSettings">
                        <div class="flex flex-col br-24" v-for="(testimonial, index) in testimonials" :key="testimonial.id" :class="index == slideIndex - 1 ? 'bg-1' : 'bg-2'">
                            <div class="min-ht-500 xs:min-ht-400 flex flex-col justify-between p-6 xs:p-8">
                                <p :class="index == slideIndex - 1 ? 'text-black' : 'text-color-2'" class="text-base sm:text-xl lin-ht-160" v-html="testimonial.quote"></p>
                                <div class="flex justify-start items-center mt-4 xs:mt-0">
                                    <div class="flex-shrink-0 mr-4">
                                        <img class="ht-60 wd-60 rounded-full" :class="testimonial.isDynamicStyle ? 'object-contain bg-white' : 'object-cover'" :src="testimonial.image" />
                                    </div>
                                    <div class="flex flex-col">
                                        <h1 class="SofiaPro-Medium text-sm">{{ testimonial.name }}</h1>
                                        <p class="text-color-1 text-xs">{{ testimonial.designation }}</p>
                                        <p class="text-color-1 text-sm">{{ testimonial.organization }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </VueSlickCarousel>

                    <div class="mt-8 flex justify-center items-center">
                        <div class="flex-shrink-0 mr-2">
                            <img @click.stop="prevSlide" class="cursor-pointer" :src="require('images/landing_page/section7/arrow_left.svg')" />
                        </div>
                        <div class="flex-shrink-0 ml-2">
                            <img @click.stop="nextSlide" class="cursor-pointer" :src="require('images/landing_page/section7/arrow_right.svg')" />
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';

export default {
    name: "section5",
    components: { VueSlickCarousel, },
    data() {
        return {
            testimonials: [
                {
                    quote: "“NYU Abu Dhabi’s Office of Social Responsibility has experienced a positive, transformative impact through our collaboration with Re:Set. Their tailored mental health and well-being solutions have become integral in fostering a culture of holistic well-being on our campus, profoundly impacting our community. Through their programs and support, we’ve witnessed a transformation in how mental health and well-being are perceived and prioritized, aligning with our institutional mission to cultivate a more inclusive and supportive work environment. We look forward to continuing this partnership.”",
                    name: "Liria Gjidija",
                    designation: "Director of Global Outreach and Programs",
                    organization: "NYU Abu Dhabi",
                    image: require('images/landing_page/section7/img1.jpg'),
                    isDynamicStyle: false,
                },
                {
                    quote: "“As an organization that is seeing rapid growth across different geographies, it is important to provide our team with the right set of tools to ensure their <span class='whitespace-nowrap'>well-being</span> is taken care of. Using the Re:Set platform, we have been able to kickstart an inclusive mental health program that meets the needs of our diverse team.”",
                    name: "Jacob Puthenparambil",
                    designation: "CEO, Redhill World",
                    organization: "",
                    image: require('images/landing_page/section7/img2.jpeg'),
                    isDynamicStyle: false,
                },
                {
                    quote: "“MEPRA was honoured to partner with Re:Set to deliver invaluable mental health support to our industry professionals. Thanks to Re:Set, our member organizations are better equipped to support their team’s mental <span class='whitespace-nowrap'>well-being</span> with a range of mental health management and support tools now easily accessible.”",
                    name: "Middle East Public Relations Association",
                    designation: "",
                    organization: "",
                    image: require('images/landing_page/section7/img3.png'),
                    isDynamicStyle: true,
                },
                {
                    quote: "“Re:Set is an incredible platform that advocates for inclusion and <span class='whitespace-nowrap'>well-being</span>; it's what we need in the world currently.”",
                    name: "Pooja Dhingra",
                    designation: "Celebrity chef and Founder, Le15",
                    organization: "",
                    image: require('images/landing_page/section7/img4.jpg'),
                    isDynamicStyle: false,
                },
                {
                    quote: "“Re:Set is a fantastic organization to work with. Their commitment to evidence and real issues really bridges the gap between scientific work and straightforward communication and engagement to the community at large.”",
                    name: "Dr. Anthony Murphy",
                    designation: "Director of Psychology Programmes (Dubai)",
                    organization: "University of Birmingham Dubai",
                    image: require('images/landing_page/section7/img5.jpeg'),
                    isDynamicStyle: false,
                },
                {
                    quote: "“Re:Set is an important platform which opens dialogues and allows the community to share their real-life experiences and ignite change. It was such a pleasure to be on a panel with Re:Set, which I found to be a balanced talk, <span class='whitespace-nowrap'>well-researched</span> and professionally conducted. It is always inspiring to hear women share their stories in an honest way.”",
                    name: "Rosemin Madhavji",
                    designation: "Influencer and Founder of RR&Co",
                    organization: "",
                    image: require('images/landing_page/section7/img6.jpeg'),
                    isDynamicStyle: false,
                },
            ],
            settings: {
                "centerMode": true,
                "arrows": true,
                "dots": false,
                "infinite": true,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "speed": 500,
                "touchThreshold": 5,
                "variableWidth": true,
                "cssEase": "linear",
            },
            slideIndex: 1,
            isMobile: false,
            mobileSettings: {
                "dots": false,
                "arrows": true,
                "dotsClass": "slick-dots custom-dot-class",
                "edgeFriction": 0.35,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 1,
                "slidesToScroll": 1,
            },
        };
    },
    mounted() {
        if(window.innerWidth > 767) {
            this.isMobile = false;
        } else {
            this.isMobile = true;
        }
    },
    methods: {
        nextSlide() {
        const sliderDiv = document.querySelector(".testimonials");
        sliderDiv.lastChild.click();
        },
        prevSlide() {
        const sliderDiv = document.querySelector(".testimonials");
        sliderDiv.firstChild.click();
        },
        beforeChange(oldSlideIndex, newSlideIndex) {
        this.slideIndex = newSlideIndex + 1;
        },
    },
}
</script>

<style lang="scss" scoped>
.text-color-1 {
    color: #524F56;
}
.text-color-2 {
    color: #00000052;
}

@responsive {
    .card-lg {
      width: 665px !important;
      min-height: 331px !important;
    }
    .card-md {
        width: 665px !important;
        min-height: 331px !important;
    }
    .card-sm {
        width: 600px !important;
        min-height: 331px !important;
    }
    .card-xs {
      width: 330px !important;
      min-height: 876px !important;
    }
    .card {
      width: 210px !important;
      min-height: 258px !important;
    }

    .lin-ht-160 {
        line-height: 160%;
    }
    .min-ht-454 {
        min-height: 454px;
    }
    .min-ht-400 {
        min-height: 400px;
    }
    .min-ht-500 {
        min-height: 500px;
    }
    .ht-90 {
        height: 90px;
    }
    .wd-90 {
        width: 90px;
    }
    .f-48 {
        font-size: 48px;
    }
    .f-28 {
        font-size: 28px;
    }
    .ht-60 {
        height: 60px;
    }
    .wd-60 {
        width: 60px;
    }
  }
  .text-color {
    color: #10083C;
  }
  .bg-yellow-lg {
    background-color: #F9F973;
  }
  .bg-grey-lg {
    background-color: #10083C0A;
  }


  .bg-1 {
      background: #F6F0FD;
  }
  .bg-2 {
      background: #F7F7F7;
  }
  .br-24 {
      border-radius: 24px;
  }

</style>

<style lang="scss">
  .testimonials {
    .slick-arrow {
      display: none !important;
    }
  }
</style>