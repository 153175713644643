<template>
  <div class="flex-auto flex flex-col p-4 items-center justify-center">
    <p class="text-lg text-center">Thank you!</p>
    <p class="text-lg text-center">We will see you in your inbox shortly.</p>
  </div>
</template>

<script>
export default {
  name: "three",
  props: {
    selectedTab: String,
  },
}
</script>

<style lang="scss" scoped>
</style>