<template>
    <div class="h-full w-full flex flex-col items-center justify-center">
        <div class="rsd__modal">
            <div class="relative">
                <div class="h-full w-full flex flex-col md:px-16 relative">
                    <div v-if="showButton" class="absolute right-0 top-0 mt-3 md:mt-4 mr-3 md:mr-4 z-50 md:pr-16">
                        <div @click.stop="closeModal" class="h-6 sm:h-8 md:h-10 w-6 sm:w-8 md:w-10 bg-1 rounded-full flex justify-center items-center cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="w-4 sm:w-5 md:w-6 h-4 sm:h-5 md:h-6 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                    </div>
                    <img @click.prevent="openUrl()" @load="onImageLoad" class="ht-50-vh hidden md:block cursor-pointer" :src="require('images/landing_page/modals/free_access/free_access_popup.png')" />
                    <img @click.prevent="openUrl()" @load="onImageLoad" class="ht-50-vh block md:hidden cursor-pointer" :src="require('images/landing_page/modals/free_access/free_access_tablet.jpg')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "free-access-modal",
    mounted (){
      document.querySelector(".hopsock-body").style.overflow = "hidden";
    },
    data() {
      return {
        showButton: false,
      };
    },
    methods: {
        onImageLoad() {
            this.showButton = true;
        },
        closeModalPopUP() {
            document.querySelector(".hopsock-body").style.overflow = "auto";
            document.querySelector(".rsd__modal").style.visibility = "hidden";
            document.querySelector(".rsd__modal").style.opacity = "0";
        },
        closeModal() {
            this.closeModalPopUP();
            let now = new Date()
            let expiryTime = now.getTime() + 24 * 60 * 60 * 1000 // 24 hours in milliseconds
            sessionStorage.setItem('nudgeShowFreeAccess', JSON.stringify({
                data: "nudgefreeaccess",
                expiryTime: expiryTime
            }))
            this.$parent.show_free_access = false;
        },
        openUrl(){
            this.closeModalPopUP();
            sessionStorage.setItem('alreadyShowFreeAccess','alreadyshownfreeaccess');
            this.$parent.show_free_access = false;
            window.open("https://forms.gle/TA1471fwaYaVSZ968");
        },
    }
}
</script>

<style lang="scss" scoped>
.rsd__modal {
    visibility: visible;
    opacity: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    transition: all .4s;
    z-index: 999;
}
.ht-50-vh {
  height: 50vh;
}
.bg-1 {
  background: #8B40E2;
}
</style>