<template>
    <div class="flex flex-col py-16 px-4">
        <div class="container mx-auto">
            <div class="flex flex-col bor-top-line py-16">
                <div class="w-full flex flex-col xs:flex-row justify-between items-center">
                    <div class="flex-shrink-0">
                        <img class="ht-36" :src="require('images/reset_youreveryday_logo.png')" />
                    </div>
                    <div class="flex justify-end items-center mt-16 xs:mt-0">
                        <a href="https://twitter.com/ReSet_Everyday" target="_blank"><img class="cursor-pointer h-8 w-8" :src="require('images/landing_page/footer/twitter.svg')" /></a>
                        <a href="https://www.facebook.com/ReSetYourEveryday" target="_blank" class="ml-8"><img class="cursor-pointer h-8 w-8" :src="require('images/landing_page/footer/facebook.svg')" /></a>
                        <a href="https://www.instagram.com/resetyoureveryday" target="_blank" class="ml-8"><img class="cursor-pointer h-10 w-10" :src="require('images/landing_page/footer/instagram.svg')" /></a>
                        <a href=" https://ae.linkedin.com/company/resetyoureveryday?trk=public_profile_experience-item_profile-section-card_subtitle-click" target="_blank" class="ml-8"><img class="cursor-pointer w-8 h-8 rounded-lg" :src="require('images/landing_page/footer/linkedin.svg')" /></a>
                        <a href="https://www.youtube.com/@resetyoureveryday" target="_blank" class="ml-8"><img class="cursor-pointer w-8 h-8 rounded-lg" :src="require('images/landing_page/footer/youtube.svg')"/></a>
                    </div>
                </div>
            </div>
            <div class="w-full flex flex-col sm:flex-row justify-between items-center">
                <div class="flex flex-col sm:flex-row justify-start items-center text-color-1 text-sm">
                    <a href="/about" class="mr-0 sm:mr-4 cursor-pointer">About</a>
                    <div @click="openPopUp('request-a-demo')" class="mr-0 sm:mr-4 mt-4 sm:mt-0 cursor-pointer">Request a Demo</div>
                    <a href="/terms" class="mr-0 sm:mr-4 mt-4 sm:mt-0 cursor-pointer">Terms</a>
                    <a href="/privacy" class="mr-0 sm:mr-4 mt-4 sm:mt-0 cursor-pointer">Privacy Policy</a>
                    <div @click="openPopUp('press')" class="mr-0 sm:mr-4 mt-4 sm:mt-0 cursor-pointer">Press</div>
                    <div @click="openPopUp('contact-us')" class="cursor-pointer mt-4 sm:mt-0">Contact Us</div>
                </div>
                <div class="flex justify-start items-center text-color-1 text-sm mt-16 sm:mt-0">
                    <div>© Re:Set 2024</div>
                </div>
            </div>
        </div>
        <!-- Press modal start -->
        <press :page="modalPageName"></press>
        <!-- Press modal end -->
        <!-- Contact us modal start -->
        <contact-us :page="modalPageName"></contact-us>
        <!-- Contact us modal end -->
    </div>
</template>

<script>
import Press from "../modals/press.vue";
import ContactUs from "../modals/contact_us.vue";
export default {
    name: "section8",
    components: { Press, ContactUs, },
    data() {
        return {
            modalPageName: "header",
        };
    },
    methods: {
        openPopUp(modalName) {
            this.$modal.show(`${this.modalPageName}-${modalName}-modal`);
        },
    },
}
</script>

<style lang="scss" scoped>
.bor-top-line {
    border-top: 1px solid #EBEBEC;
}
.text-color-1 {
    color: #524F56;
}
@responsive {
  .ht-36 {
    height: 36px;
  }
}
</style>