<template>
	<div>
		<modal class="fs-modal contact-us-modal" name="contact-us-modal" :width="getWidth" :height="getHeight" scrollable="true" :adaptive="true" :clickToClose="false">
			<div class="h-full w-full flex flex-col relative bg-lightpurple">
				<div class="absolute right-0">
					<!-- <img @click.stop="closeModal" class="cursor-pointer p-4" :src="require('images/landing_page/modals/close.svg')" /> -->
					<button
	          title="Close"
	          class="focus:outline-none absolute right-0 top-0 mr-4 mt-4 z-50"
	      	>
		        <svg
		            @click="closeModal"
		            viewBox="0 0 1024 1024"
		            version="1.1"
		            xmlns="http://www.w3.org/2000/svg"
		            xmlns:xlink="http://www.w3.org/1999/xlink"
		            width="25"
		            height="25"
		            class="icon icon-remove"
		            style="top: -5.5px; right: -15px"
		        >
		          <path
		              d="M511.921231 0C229.179077 0 0 229.257846 0 512 0 794.702769 229.179077 1024 511.921231 1024 794.781538 1024 1024 794.702769 1024 512 1024 229.257846 794.781538 0 511.921231 0ZM732.041846 650.633846 650.515692 732.081231C650.515692 732.081231 521.491692 593.683692 511.881846 593.683692 502.429538 593.683692 373.366154 732.081231 373.366154 732.081231L291.761231 650.633846C291.761231 650.633846 430.316308 523.500308 430.316308 512.196923 430.316308 500.696615 291.761231 373.523692 291.761231 373.523692L373.366154 291.918769C373.366154 291.918769 503.453538 430.395077 511.881846 430.395077 520.349538 430.395077 650.515692 291.918769 650.515692 291.918769L732.041846 373.523692C732.041846 373.523692 593.447385 502.547692 593.447385 512.196923 593.447385 521.412923 732.041846 650.633846 732.041846 650.633846Z"
		              fill="#8c40e2"
		          ></path>
		        </svg>
				  </button>
				</div>
				<div class="w-full h-full flex flex-col md:flex-row-reverse flex-no-wrap md:flex-wrap">
					<div class="h-auto md:h-full w-full md:w-1/2 flex flex-col items-end bg-white">
						<img class="w-full md:w-auto object-contain md:object-contain ht-260 md:ht-auto" :src="require('images/landing_page/modals/contact_us/contact_us_popup.png')" />
					</div>
					<div class="h-auto md:h-full w-full md:w-1/2 px-4 xs:px-8 md:px-10 flex-auto overflow-y-hidden">
						<form class="h-full" @submit.prevent="handleSubmit">
							<div class="h-full flex flex-col">
								<div class="flex flex-col mt-4 xs:mt-8 md:mt-16">
									<h1 class="SofiaPro-Medium text-black text-xl xs:text-2xl lin-ht-30 xs:lin-ht-36 text-center lg:wd-525">Feeling lost or have any feedback? Need help? Drop us a note and let us take care of it.</h1>
								</div>
								<div class="w-full flex-auto overflow-y-scroll pl-2 xs:pl-0 pr-2 xs:pr-4 mt-0 xs:mt-8 md:mt-12 mb-8 md:mb-0">
									<div class="flex flex-col">
										<div class="flex flex-row flex-wrap">
											<div class="flex flex-wrap w-full">
                        						<div class="w-full xs:w-1/2 px-2 mt-4 xs:mt-0">
                         							<div class="w-full flex justify-start ht-56 rsd-input br-28 items-center">
														<div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
															<img :src="require('images/landing_page/modals/img1.svg')">
														</div>
														<div class="h-full flex-grow flex items-center pr-4">
															<input class="input-btn outline-none text-sm w-full" v-model.trim="enteredFirstName.val" placeholder="First Name" @keyup="clearValidity('enteredFirstName')" autocomplete="off" required />
														</div>
													</div>
													<transition name="val-error">
														<p v-if="!enteredFirstName.isValid" class="text-xs text-red">
															First Name must not be empty.
														</p>
													</transition>
                        						</div>
                        						<div class="w-full xs:w-1/2 px-2 mt-4 xs:mt-0">
                        							<div class="w-full flex justify-start ht-56 rsd-input br-28 items-center">
														<div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
															<img :src="require('images/landing_page/modals/img1.svg')">
														</div>
														<div class="h-full flex-grow flex items-center pr-4">
															<input class="input-btn outline-none text-sm w-full" v-model.trim="enteredLastName.val" placeholder="Last Name" @keyup="clearValidity('enteredLastName')" autocomplete="off" required />
														</div>
													</div>
													<transition name="val-error">
														<p v-if="!enteredLastName.isValid" class="text-xs text-red">
															Last Name must not be empty.
														</p>
													</transition>
                        						</div>
											</div>
											<div class="flex flex-wrap w-full mt-4">
												<div class="w-full xs:w-1/2 px-2">
													<div class="w-full flex justify-start ht-56 rsd-input br-28 items-center">
														<div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
															<img :src="require('images/landing_page/modals/img2.svg')">
														</div>
														<div class="h-full flex-grow flex items-center pr-4">
															<input class="input-btn outline-none text-sm w-full" v-model.trim="enteredEmail.val" placeholder="Email" @keyup="clearValidity('enteredEmail')" autocomplete="off" required />
														</div>
													</div>
													<transition name="val-error">
														<p v-if="!enteredEmail.isValid" class="text-xs text-red">
															{{ enteredEmail.msg }}
														</p>
													</transition>
												</div>
												<div class="w-full xs:w-1/2 px-2 mt-4 xs:mt-0">
													<div class="w-full flex justify-start ht-56 rsd-input br-28 items-center">
														<div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
															<img :src="require('images/landing_page/modals/img3.svg')">
														</div>
														<div class="h-full flex-grow flex items-center pr-4">
															<input class="input-btn outline-none text-sm w-full" v-model.trim="enteredOrganizationName.val" placeholder="Organization Name" @keyup="clearValidity('enteredOrganizationName')" autocomplete="off" required />
														</div>
													</div>
													<transition name="val-error">
														<p v-if="!enteredOrganizationName.isValid" class="text-xs text-red">
															Organization Name must not be empty.
														</p>
													</transition>
												</div>
											</div>
										</div>
									</div>
									<div class="flex flex-col mt-4">
										<h1 class="text-color-1 text-xs pl-4">Message*</h1>
										<div class="flex flex-row flex-wrap mt-2">
											<div class="w-full">
												<div class="text-area-div xs:text-area-div-xs p-4">
													<textarea maxlength="250" class="h-full w-full text-sm outline-none resize-none" placeholder="Type your message here" v-model="enteredMessage.val" @keyup="clearValidity('enteredMessage')"/>
												</div>
												<transition name="val-error">
													<p v-if="!enteredMessage.isValid" class="text-xs text-red">
														Message must not be empty.
													</p>
												</transition>
											</div>
										</div>
									</div>
									<div class="mt-8 xs:mt-10 pb-0 md:pb-6 flex flex-col items-center">
										<div>
											<transition name="val-error">
												<p class="text-sm text-red mb-2" v-if="serverResponse.val === '0'">{{ serverResponse.msg }}</p>
											</transition>
										</div>
										<button type="submit" name="button" class="ht-56 w-100-per xs:wd-225 md:wd-309 lg:wd-389 br-28 flex justify-center items-center bg-1 px-8 cursor-pointer">
											<div class="flex justify-center SofiaPro-Medium text-white">Submit</div>
											<div class="flex-shrink-0">
												<img :src="require('images/landing_page/modals/arrow.svg')" />
											</div>
										</button>
									</div>
								</div>
								<!-- <div class="mt-4 mb-10 flex flex-col items-center">
									<div>
										<transition name="val-error">
											<p class="text-sm text-red mb-2" v-if="serverResponse.val === '0'">{{ serverResponse.msg }}</p>
										</transition>
									</div>
									<button type="submit" name="button" class="ht-56 w-100-per xs:wd-225 md:wd-309 lg:wd-389 br-28 flex justify-center items-center bg-1 px-8 cursor-pointer">
										<div class="flex justify-center SofiaPro-Medium text-white">Submit</div>
										<div class="flex-shrink-0 pl-3">
											<img :src="require('images/landing_page/modals/arrow.svg')" />
										</div>
									</button>
								</div> -->
							</div>
						</form>
					</div>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>

export default {
	name: "contact-us-modal",
	props: {
	  page: String,
	  landing_page: Boolean
	},
	mounted (){
	  this.openModal();
	},
	computed: {
		getWidth() {
			if(window.innerWidth > 1510) {
				return "1360px";
			} else {
				return '95%';
			}
		},
		getHeight() {
			if (window.innerWidth > 991) {
				return '85%';
			} else if(window.innerWidth > 767) {
				return 'auto';
			} else {
				return '85%';
			}
		},
	},
	data() {
		return {
			enteredFirstName: {
				val: "",
				isValid: true,
			},
			enteredLastName: {
				val: "",
				isValid: true,
			},
			enteredEmail: {
				val: "",
				isValid: true,
				msg: "",
			},
			enteredOrganizationName: {
				val: "",
				isValid: true,
			},
			enteredMessage: {
				val: "",
				isValid: true,
			},
			serverResponse: {
				val: "",
				msg: "",
			},
			formIsValid: true,
		};
	},
	methods: {
    openModal() {
			this.$modal.show("contact-us-modal");
		},
		closeModal() {
			this.clearInputFields();
			if(this.landing_page === false){
				window.location = "/feed?contact='false'"
			}else{
				this.$parent.show_contact_us = false
			}
			this.$modal.hide("contact-us-modal");
		},
		clearValidity(input) {
			this[input].isValid = true;
		},
		validateForm() {
			this.formIsValid = true;
			this.serverResponse.val = "";
			if (this.enteredFirstName.val === "") {
				this.enteredFirstName.isValid = false;
				this.formIsValid = false;
			}
			if (this.enteredLastName.val === "") {
				this.enteredLastName.isValid = false;
				this.formIsValid = false;
			}
			if (this.enteredMessage.val === "") {
				this.enteredMessage.isValid = false;
				this.formIsValid = false;
			}
			if (this.enteredOrganizationName.val === "") {
				this.enteredOrganizationName.isValid = false;
				this.formIsValid = false;
			}
			if (this.enteredEmail.val === "") {
				this.enteredEmail.isValid = false;
				this.enteredEmail.msg = "Email must not be empty.";
				this.formIsValid = false;
			} else {
				const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (!re.test(this.enteredEmail.val)) {
					this.enteredEmail.isValid = false;
					this.enteredEmail.msg = "You have entered an invalid email address!";
					this.formIsValid = false;
				}
			}
		},
		handleSubmit() {
			this.validateForm();
			if (!this.formIsValid) {
				return;
			}

			const data = {
				first_name: this.enteredFirstName.val,
				last_name: this.enteredLastName.val,
				email: this.enteredEmail.val,
				organization_name: this.enteredOrganizationName.val,
				message: this.enteredMessage.val,
			};
			fetch("/contact_us", {
				method: "POST",
				headers: {
				"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			})
			.then((response) => response.json())
			.then((data) => {
				this.serverResponse.val = data.code;
				this.serverResponse.msg = data.msg;
				if (data.code == "1") {
					this.$swal({
            title: '',
            text: 'Thank you for reaching out. We’ll be in touch with you shortly!',
            type: 'success',
            showConfirmButton: true,
          });
					this.$modal.hide("contact-us-modal");
					this.clearInputFields();
				} else {
					this.serverResponse.val = "0";
				}
			})
			.catch((error) => {
				this.serverResponse.val = "0";
				this.serverResponse.msg = error;
			});
		},
		clearInputFields() {
			this.enteredFirstName.val = "";
			this.enteredLastName.val = "";
			this.enteredOrganizationName.val = "";
			this.enteredEmail.val = "";
			this.enteredMessage.val = "";
			this.serverResponse.val = "";
			this.serverResponse.msg = "";
		},
	}
}
</script>

<style lang="scss" scoped>
	.fs-modal {
		background-color: #000000d6;
	}

	.bg-lightpurple{
	  background-color: #d7cdf4;
	}
	.rsd-input {
		border: 1px solid #ECECEC;
		background-color: white;
	}
	.input-btn {
		outline: 0;
	}
	.br-28 {
		border-radius: 28px;
	}
	.bg-1 {
		background: #8B40E2;
	}
	.val-error-enter-active,
	.val-error-leave-active {
		transition: opacity 1s ease;
	}
	.val-error-enter, .val-error-leave-to {
		opacity: 0;
	}
	.fade-enter-active, .fade-leave-active {
		transition: opacity .3s;
	}
	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
	.text-color-1 {
		color: #848186;
	}
	.text-color-2 {
		color: #524F56;
	}
	@responsive {
		.wd-525 {
			width: 525px;
		}
		.ht-56 {
			height: 36px;
		}
		.wd-389 {
			width: 389px;
		}
		.wd-309 {
			width: 309px;
		}
		.wd-225 {
			width: 225px;
		}
		.ht-260 {
			height: 260px;
		}
		.ht-auto {
			height: auto;
		}
		.lin-ht-36 {
			line-height: 36px;
		}
		.lin-ht-30 {
			line-height: 30px;
		}
		.w-100-per {
			width: 100%;
		}
		.lin-ht-24 {
			line-height: 24px;
		}
		.text-area-div {
			height: 100px;
			background: #FFFFFF;
			border: 1px solid #ECECEC;
			border-radius: 28px;
		}
		.text-area-div-xs {
			height: 140px;
			background: #FFFFFF;
			border: 1px solid #ECECEC;
			border-radius: 28px;
		}
	}
</style>

<style lang="scss">
	.v--modal-box.v--modal {
		border-radius: 12px;
	}
</style>