<template>
    <div class="h-full flex flex-col p-4">
        <p class="text-base xs:text-lg px-4 xs:px-6 mt-0 xs:mt-4">Access some of our premium resources to understand how we can support you on your <span style="white-space: nowrap;">well-being</span> journey.</p>
        <div class="px-4 mt-4">
            <div class="w-full flex justify-start ht-28 rsd-input br-28 items-center">
              <div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
                <img :src="require('images/landing_page/modals/img1.svg')">
              </div>
              <div class="h-full flex-grow flex items-center pr-4">
                  <input class="input-btn outline-none text-sm w-full" v-model.trim="enteredName.val" placeholder="Name" @keyup="clearValidity('enteredName')" autocomplete="off" required />
              </div>
            </div>
            <transition name="val-error">
                <p v-if="!enteredName.isValid" class="text-xs text-red">
                    Name must not be empty.
                </p>
            </transition>
            <div class="w-full mt-4 flex justify-start ht-28 rsd-input br-28 items-center">
                <div class="h-full flex-shrink-0 flex items-center w-12 justify-center">
                    <img :src="require('images/landing_page/modals/img2.svg')">
                </div>
                <div class="h-full flex-grow flex items-center pr-4">
                    <input class="input-btn outline-none text-sm w-full" v-model.trim="enteredEmail.val" placeholder="Email" @keyup="clearValidity('enteredEmail')" autocomplete="off" required />
                </div>
            </div>
            <transition name="val-error">
                <p v-if="!enteredEmail.isValid" class="text-xs text-red">
                    {{ enteredEmail.msg }}
                </p>
            </transition>
          </div>  
        <div class="flex justify-center mt-4">
          <button @click="handleSubmit" type="button" class="bg-1 text-white py-1 px-6 rounded-full cursor-pointer"><span class="text-sm">Share on My Email</span></button>
        </div>
        <div class="flex-auto flex flex-col justify-end items-end cursor-pointer mt-4" @click.prevent="openContactPopup()">
          <div class="text-sm text-color-1 leading-none">Contact Us</div>
        </div>
    </div>
</template>

<script>
export default {
  name: "two",
  emits: ["next-tab"],
  props: {
      selectedTab: String,
  },
  data() {
    return {
      enteredName: {
        val: "",
        isValid: true,
      },
      enteredEmail: {
        val: "",
        isValid: true,
        msg: "",
      },
      serverResponse: {
        val: "",
        msg: "",
      },
      formIsValid: true,
    };
  },
  methods: {
    nextTab() {
      this.$emit('next-tab', "three");
    },

    openContactPopup(){
      this.$parent.show_contact_us = true;
    },

    clearValidity(input) {
      this[input].isValid = true;
    },

    validateForm() {
      this.formIsValid = true;
      this.serverResponse.val = "";
      if (this.enteredName.val === "") {
        this.enteredName.isValid = false;
        this.formIsValid = false;
      }
      if (this.enteredEmail.val === "") {
        this.enteredEmail.isValid = false;
        this.enteredEmail.msg = "Email must not be empty.";
        this.formIsValid = false;
      }else {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(this.enteredEmail.val)) {
          this.enteredEmail.isValid = false;
          this.enteredEmail.msg = "You have entered an invalid email address!";
          this.formIsValid = false;
        }
      }
    },
    handleSubmit() {
        this.validateForm();
        if (!this.formIsValid) {
          return;
        }

        const data = {
          name: this.enteredName.val,
          email: this.enteredEmail.val,
        };
        fetch("/send_free_resource", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then((data) => {
          this.serverResponse.val = data.code;
          this.clearInputFields();
          this.nextTab();
        })
    },
    clearInputFields() {
      this.enteredName.val = "";
      this.enteredEmail.val = "";
    },
  },
}
</script>

<style lang="scss" scoped>
  .text-color-1 {
    color: #8B40E2;
  }

  .rsd-input {
    border: 1px solid #ECECEC;
    background-color: white;
  }
  .input-btn {
    outline: 0;
  }
  .br-28 {
    border-radius: 28px;
  }
  .bg-1 {
     background: #8B40E2;
  }
  .val-error-enter-active,
  .val-error-leave-active {
      transition: opacity 1s ease;
  }
  .val-error-enter, .val-error-leave-to {
      opacity: 0;
  }
  .fade-enter-active, .fade-leave-active {
      transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
      opacity: 0;
  }
  @responsive {
    .wd-525 {
        width: 525px;
    }
    .ht-28 {
      height: 36px;
    }

    .ht-auto {
        height: auto;
    }
  }
</style>