<template>
  <div id="workplace-solutions" class="flex flex-col justify-start pt-16 pb-8 xs:pb-16 px-4">
    <div class="container mx-auto">
      <h1 class="SofiaPro-Medium f-28 xs:f-48 SofiaPro-Medium text-black text-center lin-ht-140">Real results, real rewards</h1>
      <div class="flex flex-row flex-wrap mt-8 xs:mt-16">
        <div class="w-full xs:w-1/2 flex justify-center xs:justify-start">
            <div class="wd-100-per xs:wd-270 flex flex-col">
                <div class="flex justify-center">
                    <img class="h-6" :src="require('images/landing_page/section12/traingle.png')" />
                </div>
                <h1 class="text-5xl font-bold text-center leading-tight">100%</h1>
                <p class="SofiaPro-Medium text-color-2 font-light text-center">of participants reported <span class="font-bold text-black SofiaPro-Medium SofiaPro-Medium">actionable benefits</span> such as <span class="font-bold text-black SofiaPro-Medium SofiaPro-Medium">reduced stress levels</span> and <span class="font-bold text-black SofiaPro-Medium SofiaPro-Medium">improved sleep</span> after attending Re:Set Experiences.</p>
            </div>
        </div>
        <div class="w-full xs:w-1/2 flex justify-center xs:justify-end mt-8 xs:mt-0">
            <div class="wd-100-per xs:wd-314 flex flex-col">
                <div class="flex justify-center">
                    <img class="h-6 object-cover" :src="require('images/landing_page/section12/traingle.png')" />
                </div>
                <h1 class="text-5xl font-bold text-center leading-tight">100%</h1>
                <p class="SofiaPro-Medium text-color-2 font-light text-center">of participants said they will <span class="font-bold text-black SofiaPro-Medium SofiaPro-Medium">recommend</span> Re:Set <span class="SofiaPro-Medium text-color-2 font-light" style="white-space: nowrap;">Well-Being</span> Courses and Experiences underscoring the widespread <span class="font-bold text-black SofiaPro-Medium SofiaPro-Medium">endorsement</span> of the <span class="font-bold text-black SofiaPro-Medium SofiaPro-Medium">program’s effectiveness.</span></p>
            </div>
        </div>
        <div class="w-full hidden xs:flex justify-center mt-8 sm:mt-0 md:-mt-8">
            <div class="wd-240">
                <div class="flex justify-center">
                    <img class="h-6 object-cover" :src="require('images/landing_page/section12/traingle.png')" />
                </div>
                <p class="mt-2 SofiaPro-Medium text-color-2 font-light text-center">Re:Set's <span class="SofiaPro-Medium text-color-2 font-light" style="white-space: nowrap;">Well-Being</span> Courses have received an average rating of</p>
                <p class="font-bold text-black SofiaPro-Medium text-3xl text-center leading-tight">4.9 <span class="text-lg">out of</span> 5</p>
                <p class="SofiaPro-Medium text-color-2 font-light text-center">stars from our clients.</p>
            </div>
        </div>
        <div class="w-full xs:w-1/2 flex justify-center mt-8 sm:mt-0 md:-mt-8">
            <div class="wd-100-per xs:wd-344 flex flex-col">
                <div class="flex justify-center">
                    <img class="h-6 object-cover" :src="require('images/landing_page/section12/traingle.png')" />
                </div>
                <h1 class="text-5xl font-bold text-center leading-tight">98%</h1>
                <p class="SofiaPro-Medium text-color-2 font-light text-center">of participants reported <span class="font-bold text-black SofiaPro-Medium SofiaPro-Medium">tangible improvements</span> in their <span class="font-bold text-black SofiaPro-Medium">daily lives</span> and <span class="font-bold text-black SofiaPro-Medium">increased employee morale</span> and <span class="font-bold text-black SofiaPro-Medium">engagement</span> after attending Re:Set <span class="SofiaPro-Medium text-color-2 font-light" style="white-space: nowrap;">Well-Being</span> Courses.</p>
            </div>
        </div>
        <div class="w-full xs:w-1/2 flex justify-center mt-8 sm:mt-0 md:-mt-8">
            <div class="wd-100-per xs:wd-293 flex flex-col">
                <div class="flex justify-center">
                <img class="h-6 object-cover" :src="require('images/landing_page/section12/traingle.png')" />
                </div>
                <h1 class="text-5xl font-bold text-center leading-tight">98%</h1>
                <p class="SofiaPro-Medium text-color-2 font-light text-center">of participants found Re:Set’s <span class="SofiaPro-Medium text-color-2 font-light" style="white-space: nowrap;">Well-Being</span> Program <span class="font-bold text-black SofiaPro-Medium">highly engaging</span> and <span class="font-bold text-black SofiaPro-Medium">valuable</span> to their overall <span class="SofiaPro-Medium text-color-2 font-light" style="white-space: nowrap;">well-being</span>.</p>
            </div>
        </div>
        <div class="w-full xs:hidden flex justify-center mt-8">
            <div class="wd-100-per flex flex-col">
                <div class="flex justify-center">
                    <img class="h-6 object-cover" :src="require('images/landing_page/section12/traingle.png')" />
                </div>
                <p class="mt-2 SofiaPro-Medium text-color-2 font-light text-center">Re:Set's <span class="SofiaPro-Medium text-color-2 font-light" style="white-space: nowrap;">Well-Being</span> Courses have received an average rating of</p>
                <p class="font-bold text-black SofiaPro-Medium text-3xl text-center leading-tight">4.9 <span class="text-lg">out of</span> 5</p>
                <p class="SofiaPro-Medium text-color-2 font-light text-center">stars from our clients.</p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section11",
  data() {
    return {
    };
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
@responsive {
    .wd-270 {
        width: 270px;
    }
    .wd-314 {
        width: 314px;
    }
    .wd-344 {
        width: 344px;
    }
    .wd-293 {
        width: 293px;
    }
    .wd-240 {
        width: 240px;
    }
    .wd-100-per {
        width: 100%;
    }
    .wd-420 {
        width: 420px;
    }
    .ht-655 {
        height: 655px;
    }

  .lin-ht-140 {
        line-height: 140%;
    }
    .w-320{
        width: 320px;
    }
    .w-230 {
        width: 230px;
    }
    .f-48 {
        font-size: 48px;
    }
    .f-28 {
        font-size: 28px;
    }
    .f-24 {
        font-size: 24px;
    }
    .w-100-per {
        width: 100%;
    }
    .w-310 {
        width: 310px;
    }
    .w-220 {
        width: 220px;
    }
    .w-240 {
        width: 240px;
    }
    .ht-125 {
        height: 125px;
    }
    .ht-115 {
        height: 115px;
    }
    .ht-100 {
        height: 100px;
    }

    .ht-105 {
        height: 105px;
    }
    .ht-95 {
        height: 95px;
    }
    .ht-80 {
        height: 80px;
    }

    .ht-90 {
        height: 90px;
    }
    .ht-75 {
        height: 75px;
    }
}
.text-color-1 {
    color: #09040E;
}
.text-color-2 {
    color: #524F56;
}
.text-color-3 {
    color: #524f56a8;
}
.text-color-4 {
    color: #8B40E2;
}
</style>