<template>
    <div class="flex flex-col pt-16 pb-16 px-4">
        <div class="container mx-auto">
            <div class="flex flex-row-reverse flex-wrap">
                <div class="w-full sm:w-1/2 md:w-3/5">
                    <img :src="require('images/landing_page/section1/img1.png')" class="hidden xs:flex br-24">
                    <img :src="require('images/landing_page/section1/img1-xs.png')" class="block xs:hidden w-full br-24">
                </div>
                <div class="w-full sm:w-1/2 md:w-2/5 flex flex-col justify-center mt-8 sm:mt-0">
                    <h1 class="SofiaPro-Medium f-40 xs:f-56 md:f-66 lg:f-80 lin-ht-116 text-color-1"><span class="SofiaPro-Medium f-40 xs:f-56 md:f-66 lg:f-80 lin-ht-116 text-color-1" style="white-space: nowrap;">Well-being,</span> well done.</h1>
                    <p class="text-color-2 text-base xs:text-xl lin-ht-150 mt-4 wd-227 xs:w-100-per">Proven workplace wellness programs made just for your team.</p>
                    <div class="flex flex-col xs:flex-row justify-start items-center mt-12">
                        <div @click="openRequestADemoPopUp" class="flex items-center justify-center ht-56 w-100-per xs:wd-240 bg-1 br-28 cursor-pointer mr-0 xs:mr-4 px-8">
                            <div class="text-white flex-grow sm:flex-shrink-0 lg:flex-grow flex justify-center SofiaPro-Medium">Speak with us</div>
                            <div class="flex-shrink-0 ml-0 sm:ml-2 lg:ml-0">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                </svg>
                            </div>
                        </div>
                        <div @click.stop="scrollToElement('#lets-go', -88)" class="ht-56 w-100-per xs:wd-240 bg-white br-28 bor-line cursor-pointer text-color-3 flex items-center justify-center mt-2 xs:mt-0 SofiaPro-Medium">Our offerings</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "section1",
    data() {
        return {
            modalPageName: "header",
        };
    },
    methods: {
        openRequestADemoPopUp() {
            this.$modal.show(`${this.modalPageName}-request-a-demo-modal`);
        },
        scrollToElement(selector, yOffset = 0) {
            const el = document.querySelector(selector);
            if (el) {
                const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }       
        },
    },
}
</script>

<style lang="scss" scoped>
@responsive {
    .f-80 {
        font-size: 80px;
    }
    .f-66 {
        font-size: 66px;
    }
    .f-56 {
        font-size: 56px;
    }
    .f-40 {
        font-size: 40px;
    }
    .lin-ht-116 {
        line-height: 116%;
    }
    .lin-ht-150 {
        line-height: 150%;
    }
    .ht-56 {
        height: 56px;
    }
    .w-100-per {
        width: 100%;
    }
    .wd-240 {
        width: 240px;
    }
    .wd-227 {
        width: 227px;
    }
}
.text-color-1 {
    color: #09040E;
}
.text-color-2 {
    color: #524F56;
}
.text-color-3 {
    color: #8B40E2;
}
.bg-1 {
    background: #8B40E2;
}
.br-28 {
    border-radius: 28px;
}
.bor-line {
    border: 1px solid #DCC6F6;
}
.br-24 {
    border-radius: 24px;
}
@media only screen and (max-height: 768px) and (min-height: 648px) {
    .pt-16 {
        padding-top: 0px;
    }
}
</style>