import LandingPage from '../../views/static/landing_page.vue';

document.addEventListener('DOMContentLoaded', () => {
  var element = document.getElementById('vue')
  if (element != null) {
    var app = new Vue({
      el: element,
      components: {
        'landing-page': LandingPage
      }
    })
  }
});
